import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
} from "@material-ui/core"
import {
  AccessibilityNew as AccessibilityIcon,
  DriveEta as CarIcon,
  AccountTree as SitemapIcon,
} from "@material-ui/icons"
import NextLink from "next/link"
import { useAppDispatch, useTypedSelector } from "store"
import styled from "styled-components"
import { toggleDrawer } from "./layoutSlice"

const StyledDrawer = styled(({ ...props }) => (
  <MuiDrawer classes={{ paper: "paper" }} {...props} />
))`
  & .paper {
    width: 240px;

    ${(p) => p.theme.breakpoints.up("md")} {
      width: 280px;
    }
  }
`

const Drawer = () => {
  const { drawerOpen } = useTypedSelector((state) => state.layout)

  const dispatch = useAppDispatch()

  const onClose = () => dispatch(toggleDrawer())

  return (
    <StyledDrawer open={drawerOpen} onClose={onClose}>
      <List component="div">
        <NextLink href="/" passHref>
          <ListItem component={Button} onClick={onClose} autoFocus>
            <ListItemIcon>
              <CarIcon />
            </ListItemIcon>
            <ListItemText primary="דגמים" />
          </ListItem>
        </NextLink>

        <NextLink href="/sitemap" passHref>
          <ListItem component={Button} onClick={onClose}>
            <ListItemIcon>
              <SitemapIcon />
            </ListItemIcon>
            <ListItemText primary="מפת אתר" />
          </ListItem>
        </NextLink>

        <ListItem
          component={Button}
          onClick={onClose}
          href="https://www.mazda.co.il/נגישות/"
        >
          <ListItemIcon>
            <AccessibilityIcon />
          </ListItemIcon>
          <ListItemText primary="הצהרת נגישות" />
        </ListItem>
      </List>
    </StyledDrawer>
  )
}

export default Drawer
