import { IconButton } from "@material-ui/core"
import NextLink from "common/NextLink"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import React, { useCallback, useEffect } from "react"
import { useAppDispatch, useTypedSelector } from "store"
import styled, { createGlobalStyle } from "styled-components"
import Drawer from "./Drawer"
import Footer from "./Footer"
import Header, { StyledGeelyLogo } from "./Header"
import { AllMenus, LayoutProvider, useLayoutContext } from "./LayoutContext"
import { toggleContactPopUp } from "./layoutSlice"

const ContactDialog = dynamic(() => import("./contact/ContactDialog"), {
  ssr: false,
})

const GlobalStyle = createGlobalStyle`
  :root,
  body,
  #__next {
    height: 100%;
  }

  #__next {
    display: flex;
    flex-direction: column;
  }
`
const SkipMainLink = styled.a`
  top: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;

  :focus,
  :active {
    color: #fff;
    background-color: #000;
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow: auto;
    margin: 10px 35%;
    padding: 5px;
    border-radius: 15px;
    border: 4px solid ${(p) => p.theme.colors.lightBlue};
    text-align: center;
    font-size: 1.2em;
    z-index: 1200;
  }

  a {
    color: #fff;
  }
`

export const StyledMain = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`

const Layout: React.FC = ({ children }) => {
  const { pathname } = useRouter()
  const { focusMain, setMainRef } = useLayoutContext()
  const { popUpOpen, footerMenuDataItems } = useTypedSelector(
    (state) => state.layout
  )

  useEffect(() => {
    focusMain()
  }, [pathname])

  const mainRef = useCallback(
    (node: HTMLDivElement) => setMainRef(node),
    [setMainRef]
  )

  const dispatch = useAppDispatch()
  const { selectedBatteryCode } = useTypedSelector((state) => state.detailing)
  const { selectedColorCode } = useTypedSelector((state) => state.color)
  const menus = (children as any)?.props?.menus as AllMenus
  return (
    <>
      <GlobalStyle />
      <SkipMainLink
        href="#"
        tabIndex={2}
        accessKey="s"
        onClick={(event) => {
          event.preventDefault()

          focusMain()
        }}
      >
        דלג לתוכן המרכזי (מקש קיצור s)
      </SkipMainLink>
      <SkipMainLink href="#footer" accessKey="1" tabIndex={3}>
        מעבר ל״לסרגל ניווט תחתון״ (מקש קיצור 1)
      </SkipMainLink>
      <SkipMainLink
        tabIndex={4}
        href=""
        accessKey="2"
        onClick={(event) => {
          event.preventDefault()
          dispatch(toggleContactPopUp())
        }}
      >
        מעבר ל״יצירת קשר״ (מקש קיצור 2)
      </SkipMainLink>
      <NextLink
        passHref
        href={
          footerMenuDataItems?.menuItems.filter(
            (menuItem) => menuItem.title === "הצהרת נגישות"
          )[0].file.imageUrl
        }
      >
        <SkipMainLink accessKey="3" tabIndex={5}>
          מעבר ל״הצהרת נגישות״ (מקש קיצור 3)
        </SkipMainLink>
      </NextLink>
      <NextLink passHref href="/sitemap">
        <SkipMainLink accessKey="4" tabIndex={6}>
          מעבר ל״מפת האתר״ (מקש קיצור 4)
        </SkipMainLink>
      </NextLink>
      {!pathname.includes("/[showRoomModel]") && (
        <Header menu={menus?.headerMenuItems} sideMenu={menus?.sideMenuItems} />
      )}
      {pathname.includes("/[showRoomModel]") && (
        <IconButton style={{ padding: 20 }} aria-label="" color="primary">
          <StyledGeelyLogo
            width="75"
            height="40"
            src="/new-logo-geely@3x.png"
            alt="logo"
          />
        </IconButton>
      )}

      <Drawer />
      <StyledMain id="mainContent" tabIndex={-1} ref={mainRef}>
        {children}
      </StyledMain>
      {popUpOpen && <ContactDialog />}
      {!pathname.includes("/[showRoomModel]") &&
        pathname !== "/[model]" &&
        pathname !== "/" && (
          <>
            <Footer isPurchasedPage={false} menu={menus?.footerMenuItems} />
          </>
        )}

      {/* For Facebook marketing: */}
      <div
        id="product-id"
        data-id={`${selectedBatteryCode}_${selectedColorCode}`}
        style={{ display: "none" }}
      ></div>
    </>
  )
}

const _Layout: React.FC = (props) => (
  <LayoutProvider>
    <Layout {...props} />
  </LayoutProvider>
)

export default _Layout
