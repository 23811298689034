import { createSlice } from "@reduxjs/toolkit"
import {
  extractData,
  getEcommUpholsteries,
  Upholstery,
  UpholsteryDtoUnionComponent,
} from "api"
import { updatePriceAndURL } from "features/wizard/wizardSlice"
import { createAsyncAppThunk } from "store"

const name = "upholstery"

export type UpholsteryState = {
  upholsteries: UpholsteryDtoUnionComponent[]
  selectedUpholsteryCode: Upholstery["code"]
}

const initialState: UpholsteryState = {
  upholsteries: [],
  selectedUpholsteryCode: "",
}

export const fetchUpholsteries = createAsyncAppThunk(
  `${name}/fetchUpholsteries`,
  async (
    {
      batteryCode,
      colorCode,
      finishingLevelCode,
    }: { batteryCode: string; colorCode: string; finishingLevelCode },
    { dispatch, getState }
  ) => {
    // here we'll get upholsteries whole upholsteries data

    const upholsteries = await getEcommUpholsteries(
      batteryCode,
      colorCode,
      finishingLevelCode
    ).then(extractData)

    const selectedUpholstery = getState().upholstery?.selectedUpholsteryCode
    if (selectedUpholstery) {
      // If the selected upholstery doesn't exist anymore in the new set of
      // upholsteries, reset the selected upholstery
      if (!upholsteries.some((u) => u.details.code === selectedUpholstery)) {
        dispatch(setSelectedUpholstery(""))
      }
    }

    return upholsteries
  }
)

export const setSelectedUpholstery = createAsyncAppThunk(
  `${name}/setSelectedUpholstery`,
  (upholsteryCode: UpholsteryState["selectedUpholsteryCode"], { dispatch }) => {
    dispatch(updatePriceAndURL())
  }
)

export const resetUpholsteries = createAsyncAppThunk(
  `${name}/resetUpholsteries`,
  (_, { dispatch }) => {
    dispatch(setSelectedUpholstery(""))
  }
)

const upholstery = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpholsteries.fulfilled, (state, { payload }) => {
        state.upholsteries = payload
      })

      .addCase(setSelectedUpholstery.pending, (state, { meta }) => {
        state.selectedUpholsteryCode = meta.arg
      })
      .addCase(resetUpholsteries.pending, (state) => {
        state.upholsteries = []
      })
  },
})

export default upholstery.reducer
