import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Color, ColorDtoUnionComponent, extractData, getEcommColors } from "api"
import {
  fetchUpholsteries,
  resetUpholsteries
} from "features/upholstery/upholsterySlice"
import { updatePriceAndURL } from "features/wizard/wizardSlice"
import { createAsyncAppThunk } from "store"

const name = "color"

export type ColorState = {
  colors: ColorDtoUnionComponent[]
  selectedColorCode: Color["code"]
  loading: boolean
  displayMode: "colors" | "upholsteries"
}

const initialState: ColorState = {
  colors: [],
  selectedColorCode: "",
  loading: true,
  displayMode: "colors",
}

interface FetchColorsProps {
  batteryCode: string
  finishingLevelCode: string
}

export const fetchColors = createAsyncAppThunk(
  `${name}/fetchColors`,
  async (
    { batteryCode, finishingLevelCode }: FetchColorsProps,
    { dispatch, getState }
  ) => {
    const colors = await getEcommColors(batteryCode, finishingLevelCode)
      .then(extractData)

    const selected = getState().color.selectedColorCode
    if (selected) {
      // Check if the selected color still exists in the new set of colors
      if (colors.some((c) => c.details.code === selected)) {
        // If the selected color still exists, that means that the user has
        // selected a different detailing, so to have updated prices for the
        // upholsteries, we need to re-fetch them
        dispatch(
          fetchUpholsteries({
            batteryCode,
            finishingLevelCode,
            colorCode: selected,
          })
        )
      } else {
        // If the selected color doesn't exists anymore, reset the selected color
        dispatch(setSelectedColor(""))
      }
    }

    return colors
  }
)

export const setSelectedColor = createAsyncAppThunk(
  `${name}/setSelectedColor`,
  (colorCode: string, { getState, dispatch }) => {
    dispatch(updatePriceAndURL())
    dispatch(resetDisplayMode)

    if (colorCode) {
      const {
        selectedBatteryCode: batteryCode,
        selectedFinishLevelCode: finishingLevelCode,
      } = getState().detailing
      dispatch(
        fetchUpholsteries({ batteryCode, colorCode, finishingLevelCode })
      )
    } else {
      dispatch(resetUpholsteries())
    }
  }
)

const color = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, { payload }: PayloadAction<ColorState["loading"]>) {
      state.loading = payload
    },
    setDisplayMode(
      state,
      { payload }: PayloadAction<ColorState["displayMode"]>
    ) {
      state.displayMode = payload
    },
    resetDisplayMode(state) {
      state.displayMode = "colors"
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchColors.fulfilled, (state, { payload }) => {
        state.colors = payload
      })
      .addCase(setSelectedColor.pending, (state, { meta }) => {
        state.selectedColorCode = meta.arg
      })
  },
})

export const { setLoading, setDisplayMode, resetDisplayMode } = color.actions

export default color.reducer
