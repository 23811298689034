import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  MenuItem,
} from "@material-ui/core"
import { MenusItems } from "api"
import { trackMenuClick } from "common/analytics"
import NextLink from "common/NextLink"
import Image from "next/image"
import { useRouter } from "next/router"
import { useRef, useState } from "react"
import styled from "styled-components"
import { useLayoutContext } from "./LayoutContext"

const StyledListSubItemTitle = styled(MenuItem).attrs({
  variant: "h4",
  button: true,
  tabIndex: 0,
})<{ $isBlue?: boolean; target?: string; component: string }>`
  margin: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  .MuiListItemText-primary {
    color: ${(p) => (p.$isBlue ? p.theme.colors.niceBlue : "")};
    font-weight: ${(p) => (p.$isBlue ? "500" : "normal")};
  }
  > span {
    margin-right: 12px !important;
  }
`

const StyledSubMenuItems = styled(List).attrs({
  component: "div",
  disablePadding: true,
})`
  list-style: none;
  padding: 0%;
  margin: 0%;
`
const StyledListSecondSubItem = styled(ListItem).attrs({
  button: true,
  component: "a",
  tabIndex: 0,
})<{ target?: string }>`
  .span {
    text-decoration: none !important;
  }
`
const StyledDivider = styled(Divider)`
  margin: 16px;
`
const StyledListSubItemText = styled(ListItemText)`
  text-decoration: none;
`

type HeaderSubListItemProps = {
  subListItem: MenusItems
  isLast: boolean
  closeMenu: () => void
}

const HeaderSubListItem = ({
  subListItem,
  isLast,
  closeMenu,
}: HeaderSubListItemProps) => {
  const [collapseSecondSubMenu, setCollapseSecondSubMenu] =
    useState<boolean>(false)
  const { pathname } = useRouter()
  const { selectedScrollSection } = useLayoutContext()
  const subItemRef = useRef()

  const focusToChildren = () => {
    if (subItemRef?.current) {
      const subitem = subItemRef?.current as HTMLElement
      subitem?.focus()
    }
  }
  const sendEventLink = (pageName: string) => {
    // only if we are on the landings page!
    if (pathname === "/") {
      trackMenuClick({
        menuLocation: "top",
        tabName: selectedScrollSection,
        LinkText: pageName,
      })
    }
  }
  return (
    <>
      {subListItem.subItems?.length > 0 ? (
        <StyledListSubItemTitle
          component="li"
          id={subListItem.title}
          aria-label={`פתיחת תפריט ${subListItem.title}`}
          aria-controls={
            collapseSecondSubMenu ? `menu_${subListItem.id}` : undefined
          }
          $isBlue={subListItem.subItems?.length > 0 && collapseSecondSubMenu}
          onClick={(e) => {
            e.stopPropagation()
            setCollapseSecondSubMenu((prevState) => !prevState)
          }}
          key={subListItem.id}
          autoFocus
        >
          <StyledListSubItemText primary={subListItem.title} />
          {!collapseSecondSubMenu && (
            <Image
              src="/noun_black_Arrow.webp"
              width="8"
              height="5"
              alt="arrow-icon"
              objectFit="contain"
            />
          )}
          {collapseSecondSubMenu && (
            <Image
              src="/noun_blue_Arrow.webp"
              width="8"
              height="5"
              alt="arrow-icon"
              objectFit="contain"
            />
          )}
        </StyledListSubItemTitle>
      ) : (
        <NextLink
          href={subListItem.file?.imageUrl || subListItem.externalUrl}
          passHref
        >
          <StyledListSubItemTitle
            id={subListItem.title}
            component="a"
            aria-label={`קישור ל${subListItem.title}${
              isLast ? `,לחץ/י על טאב כדי לצאת מהרשימה` : ``
            }${subListItem.file?.imageUrl ? `,הורדת קובץ PDF` : ``}`}
            onClick={() => {
              sendEventLink(subListItem.title)
              closeMenu()
            }}
          >
            <StyledListSubItemText primary={subListItem.title} />
          </StyledListSubItemTitle>
        </NextLink>
      )}
      {subListItem.subItems?.length > 0 && (
        <Collapse
          onEnter={focusToChildren}
          in={collapseSecondSubMenu}
          timeout="auto"
        >
          <StyledSubMenuItems>
            {subListItem.subItems.map((subSecondListItem, i) => (
              <NextLink
                href={
                  subSecondListItem.file?.imageUrl ||
                  subSecondListItem.externalUrl
                }
                key={subSecondListItem.id}
                openInNewTab={subSecondListItem.isNewTab}
                passHref
              >
                <StyledListSecondSubItem
                  id={subSecondListItem.title}
                  ref={subItemRef}
                  aria-label={`קישור ל${
                    subSecondListItem.title
                  },נווט בין האלמנטים עם חץ למטה/למעלה${
                    i + 1 === subListItem.subItems.length &&
                    `,לחץ/י על טאב כדי לצאת מהרשימה`
                  }`}
                  onClick={() => sendEventLink(subSecondListItem.title)}
                >
                  <StyledListSubItemText primary={subSecondListItem.title} />
                </StyledListSecondSubItem>
              </NextLink>
            ))}
          </StyledSubMenuItems>
          {!isLast && <StyledDivider />}
        </Collapse>
      )}
    </>
  )
}

export default HeaderSubListItem
