import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  Toolbar,
} from "@material-ui/core"
import { MenusResponse } from "api"
import { Desktop, Mobile, useIsDesktop } from "common/MediaQueries"
import { HeaderNavigationLinks } from "common/NavigationLinks"
import NextLink from "common/NextLink"
import { trackContentUsClick, trackIconButtonClick } from "common/analytics"
import dynamic from "next/dynamic"
import Image from "next/image"
import { useState } from "react"
import { useAppDispatch, useTypedSelector } from "store"
import styled from "styled-components"
import HeaderListItem from "./HeaderListItem"
import { useLayoutContext } from "./LayoutContext"
import SideDrawer from "./SideDrawer"
import { toggleContactPopUp } from "./layoutSlice"

const Popup = dynamic(() => import("features/popups/Popup"), { ssr: false })

export const MOBILE_HEIGHT = 68
export const DESKTOP_HEIGHT = 80

const StyledAppBar = styled(AppBar)`
  position: fixed !important;
  ${(p) => p.theme.breakpoints.down("sm")} {
    height: ${MOBILE_HEIGHT}px;
    z-index: ${(p) => p.theme.zIndex.modal};
  }
  border-bottom: 1px solid ${(p) => p.theme.colors.silver};

  ${(p) => p.theme.breakpoints.up("md")} {
    height: ${DESKTOP_HEIGHT}px;
  }

  @media print {
    display: none;
  }
`
const StyledToolbar = styled(Toolbar)`
  flex-grow: 1;
  background-color: white;
`

const StyledHeaderSide = styled.div<{ $justify: "start" | "end" }>`
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: ${(p) => `flex-${p.$justify}`};
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledChatBotLogo = styled.img`
  height: 25px;
`
export const StyledGeelyLogo = styled(Image)`
  width: 55px;
`

const StyledCategories = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 0 25px;
  ${(p) => p.theme.breakpoints.down("md")} {
    gap: 10px;
    padding: 0 10px;
  }

  a {
    text-decoration: none;
    &:hover,
    &:focus {
      > div {
        color: ${(p) => p.theme.colors.midnight};
      }
    }
    ${(p) => p.theme.breakpoints.up("md")} {
      display: flex;
      align-items: center;
    }
  }
`

const StyledContectButton = styled(Button)`
  font-size: 16px;
  color: ${(p) => p.theme.colors.midnight};
  padding-right: 16px;
  min-width: 115px;
  white-space: nowrap;
`
type Props = {
  menu: MenusResponse
  sideMenu: MenusResponse
}
const Header = ({ menu, sideMenu }: Props) => {
  const isDesktop = useIsDesktop()
  const [phonePopupOpen, setPhonePopupOpen] = useState(false)
  const { logoAlt, headerMenuDataItems } = useTypedSelector(
    (state) => state.layout
  )
  const { selectedScrollSection } = useLayoutContext()
  const dispatch = useAppDispatch()

  const headerMenu = headerMenuDataItems || menu

  const chatBotLogo = (
    <StyledChatBotLogo width="31" height="25" src="/chatBotLogo.svg" alt="" />
  )
  const phoneLogo = <img width="19" height="19" src="/phone.svg" alt="" />

  const geelyLogo = (
    <StyledGeelyLogo
      width="55"
      height="28"
      src="/new-logo-geely@3x.png"
      alt={logoAlt}
      priority
    />
  )

  const sendEventIcon = (iconName: "whatsapp" | "telephone") => {
    trackIconButtonClick({ IconName: iconName, tabName: selectedScrollSection })
  }

  const openPopup = () => {
    trackContentUsClick({ location: "top" })
    dispatch(toggleContactPopUp())
  }

  return (
    <>
      <StyledAppBar position={isDesktop ? "static" : "relative"}>
        <StyledToolbar disableGutters>
          {/* Right */}
          <StyledHeaderSide $justify="start">
            <Box ml={2} clone>
              <StyledButtonContainer>
                <SideDrawer menu={sideMenu} />
                <Desktop>
                  <IconButton
                    aria-label="טלפון, פתיחת חלון של מספר טלפון"
                    onClick={() => {
                      sendEventIcon("telephone")
                      setPhonePopupOpen(true)
                    }}
                  >
                    {phoneLogo}
                  </IconButton>

                  <StyledContectButton
                    onClick={openPopup}
                    startIcon={chatBotLogo}
                  >
                    ליצירת קשר
                  </StyledContectButton>
                </Desktop>
                <Mobile aboveTheFold>
                  <IconButton
                    onClick={openPopup}
                    aria-label=""
                    color="primary"
                    href=""
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chatBotLogo}
                  </IconButton>
                </Mobile>
              </StyledButtonContainer>
            </Box>
          </StyledHeaderSide>

          {/* Center */}
          <Desktop aboveTheFold>
            <StyledCategories
              aria-label={`רשימה עם ${
                Object.values(HeaderNavigationLinks).length
              } פריטים`}
            >
              {headerMenu?.menuItems?.length > 0 &&
                headerMenu.menuItems.map((menuItem) => (
                  <HeaderListItem key={menuItem.id} menuItem={menuItem} />
                ))}
            </StyledCategories>
          </Desktop>
          <Mobile aboveTheFold>
            <NextLink href="/" passHref>
              <IconButton aria-label="" color="primary">
                {geelyLogo}
              </IconButton>
            </NextLink>
          </Mobile>

          {/* Left */}
          <StyledHeaderSide $justify="end">
            <Desktop>
              <Box mr={2} clone>
                <NextLink href="/" passHref>
                  <IconButton
                    tabIndex={1}
                    aria-label="תמונת לוגו Geely מעבר לדף הבית"
                    color="primary"
                  >
                    {geelyLogo}
                  </IconButton>
                </NextLink>
              </Box>
            </Desktop>
            <Mobile>
              <Box mr={2} clone>
                <StyledButtonContainer>
                  <IconButton
                    onClick={() => sendEventIcon("telephone")}
                    aria-label="טלפון, קישור לטלפון"
                    color="primary"
                    href="tel:0778042207"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {phoneLogo}
                  </IconButton>
                </StyledButtonContainer>
              </Box>
            </Mobile>
          </StyledHeaderSide>
        </StyledToolbar>
      </StyledAppBar>
      {phonePopupOpen && (
        <Popup
          keepMounted
          open={phonePopupOpen}
          onClose={() => setPhonePopupOpen(false)}
        >
          <Link
            color="primary"
            href="tel:0778042207"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="קישור לטלפון"
          >
            077-8042207
          </Link>
        </Popup>
      )}
    </>
  )
}

export default Header
