const tokenKey = "token"

export const getToken = () => localStorage.getItem(tokenKey)
export const setToken = (token: string) => localStorage.setItem(tokenKey, token)
export const clearToken = () => localStorage.removeItem(tokenKey)

const ctaKey = "hideAuthCTA"

export const getHideCTA = () => localStorage.getItem(ctaKey) === "true"
export const setHideCTA = () => localStorage.setItem(ctaKey, "true")
export const resetHideCTA = () => localStorage.removeItem(ctaKey)
