import { FormHelperText } from "@material-ui/core"
import CheckBoxOutlineBlankOutlined from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxOutlined from "@material-ui/icons/CheckBoxOutlined"
import { Field, useFormikContext } from "formik"
import { CheckboxWithLabel } from "formik-material-ui"
import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  position: relative;
  margin-bottom: 0.5em;
  text-align: right;
`
const StyledCheckboxWithLabel = styled(CheckboxWithLabel)`
  color: ${({ theme }) => theme.colors.textGrey};

  &.Mui-checked {
    color: ${({ theme }) => theme.colors.mainBlue};
  }
`

const StyledError = styled(FormHelperText)`
  /* position: absolute; */
  bottom: -0.5em;
  right: 0;
`

type FormikCheckboxFieldProps = {
  name: string
  label: React.ReactNode
  required?: boolean
  inputRef?: React.RefObject<HTMLButtonElement>
}

const FormikCheckboxField = ({
  name,
  label,
  required,
  inputRef,
}: FormikCheckboxFieldProps) => {
  const { errors, touched } = useFormikContext<{ [field: string]: boolean }>()
  return (
    <StyledContainer>
      <Field
        component={StyledCheckboxWithLabel}
        type="checkbox"
        required={required}
        icon={<CheckBoxOutlineBlankOutlined color="inherit" />}
        checkedIcon={<CheckBoxOutlined color="inherit" />}
        name={name}
        Label={{ label, style: { marginLeft: 0 } }}
        inputRef={inputRef}
        inputProps={{ "aria-describedby": `${name}-helper-text` }}
      />
      {errors[name] && touched[name] && required && (
        <StyledError id={`${name}-helper-text`} error>
          {errors[name]}
        </StyledError>
      )}
    </StyledContainer>
  )
}

export default FormikCheckboxField
