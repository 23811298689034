import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import Drawer from "@material-ui/core/Drawer"
import CloseIcon from "@material-ui/icons/Close"
import { MenusResponse } from "api"
import React, { useState } from "react"
import { useTypedSelector } from "store"
import styled from "styled-components"
import CustomedListItem from "./CustomedListItem"

const Styled = {
  UserIcon: styled.img`
    width: 22px;
  `,
  DrawerWrapper: styled.div`
    width: 260px;
  `,

  CloseButton: styled(Button)`
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    padding-right: 46px;
    margin-bottom: 30px;
    width: 100%;
    justify-content: flex-start;
    :hover {
      background-color: ${(p) => p.theme.colors.paleGrey} !important;
    }
    svg {
      margin-left: 10px;
    }
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-bottom: 30px;
    }
  `,
  Divider: styled(Divider)`
    margin: 15px 50px;
  `,

  ListItem: styled(ListItem).attrs({
    button: true,
    component: "a",
  })<{ target?: string }>`
    padding-right: 50px;
    .span {
      text-decoration: none !important;
    }
  `,
  ListItemTitle: styled(Typography).attrs({
    variant: "h3",
    component: "h3",
  })`
    margin: 0;
    padding-right: 50px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
  `,
  ListItemText: styled(ListItemText)<{
    $isBold?: boolean
  }>`
    text-decoration: none;
    .MuiListItemText-primary {
      font-weight: ${(p) => (p.$isBold ? "bold" : "normal")};
    }
  `,

  SubMenuItems: styled.div``,
}

const menuLogo = <img width="24" height="18" src="/menu-icon.svg" alt="" />

type DrawerProps = {
  menu: MenusResponse
}
const SideDrawer = ({ menu }: DrawerProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { sideMenuDataItems } = useTypedSelector((state) => state.layout)

  const sideMenu = sideMenuDataItems || menu

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return
      }

      setIsOpen(open)
    }

  return (
    <>
      <React.Fragment>
        <IconButton
          onClick={toggleDrawer(true)}
          aria-label="פתיחת תפריט צד"
          color="primary"
        >
          {menuLogo}
        </IconButton>
      </React.Fragment>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        <Styled.DrawerWrapper
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <Styled.CloseButton onClick={toggleDrawer(false)}>
              <CloseIcon /> סגירה
            </Styled.CloseButton>
            {sideMenu?.menuItems?.length > 0 && (
              <>
                {sideMenu.menuItems.map((menuItem, index) => (
                  <CustomedListItem
                    key={menuItem.id}
                    menuItem={menuItem}
                    isLast={sideMenu.menuItems.length === index + 1}
                  />
                ))}
              </>
            )}
          </List>
        </Styled.DrawerWrapper>
      </Drawer>
    </>
  )
}

export default SideDrawer
