import * as yup from "yup"

const schemaNoLead = yup
  .object({
    firstName: yup
      .string()
      .required("שדה חובה")
      .max(50, "ניתן להזין עד 50 תווים"),
    lastName: yup
      .string()
      .required("שדה חובה")
      .max(50, "ניתן להזין עד 50 תווים"),
    phone: yup
      .string()
      .required("שדה חובה")
      .matches(/^05\d-?\d{7}$/, "מספר נייד שגוי"),
    email: yup.string().email('דוא"ל שגוי').required("שדה חובה"),
    agency: yup.string().required("יש לבחור אולם תצוגה").defined(),
    agreeMarketingOffers: yup.boolean().defined(),
    remarks: yup.string(),
    captchaCode: yup.string(),
  })
  .defined()

const schemaLeadNotRequired = yup
  .object({
    firstName: yup
      .string()
      .required("שדה חובה")
      .max(50, "ניתן להזין עד 50 תווים"),
    lastName: yup
      .string()
      .required("שדה חובה")
      .max(50, "ניתן להזין עד 50 תווים"),
    phone: yup
      .string()
      .required("שדה חובה")
      .matches(/^05\d-?\d{7}$/, "מספר נייד שגוי"),
    email: yup.string().email('דוא"ל שגוי').required("שדה חובה"),
    agency: yup.string().required("יש לבחור אולם תצוגה").defined(),
    agreeMarketingOffers: yup.boolean().defined(),
    remarks: yup.string(),
    captchaCode: yup.string(),
    leadLegalCheckBox: yup.boolean(),
  })
  .defined()

const schemaLeadRequired = yup
  .object({
    firstName: yup
      .string()
      .required("שדה חובה")
      .max(50, "ניתן להזין עד 50 תווים"),
    lastName: yup
      .string()
      .required("שדה חובה")
      .max(50, "ניתן להזין עד 50 תווים"),
    phone: yup
      .string()
      .required("שדה חובה")
      .matches(/^05\d-?\d{7}$/, "מספר נייד שגוי"),
    email: yup.string().email('דוא"ל שגוי').required("שדה חובה"),
    agency: yup.string().required("יש לבחור אולם תצוגה").defined(),
    agreeMarketingOffers: yup.boolean().defined(),
    remarks: yup.string(),
    captchaCode: yup.string(),
    leadLegalCheckBox: yup.mixed().oneOf([true], "שדה חובה"),
  })
  .defined()

export default { schemaLeadNotRequired, schemaNoLead, schemaLeadRequired }
