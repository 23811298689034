import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  CompletePaymentResponse,
  CreateOrderRequest,
  EditOrderRequest,
  extractData,
  postEcommOrders,
  postEcommOrdersComplete,
  postEcommPaymentRedirectTransaction,
  postLeadNewLeftLead,
  putEcommOrders,
  ThickUserInfo,
  TransactionParams,
} from "api"
import {
  generateVerificationCode,
  logout,
  setOpen,
} from "features/auth/authSlice"
import { setPaymentForm } from "features/popups/popupSlice"
import { createAsyncAppThunk } from "store"

const name = "payment"

const OUT_OF_STOCK_ERROR_CODE = 70000

type PaymentState = {
  orderDetails: ThickUserInfo | null
  creditGuardUrl: string
  transactionId: string
  transactionComplete: CompletePaymentResponse | null
  orderError: string
  orderId: string
  showOutOfStock: boolean
}

export const initialState: PaymentState = {
  orderDetails: null,
  creditGuardUrl: "",
  transactionComplete: null,
  transactionId: "",
  orderError: "",
  orderId: "",
  showOutOfStock: false,
}

export const submitOrderForm = createAsyncAppThunk(
  `${name}/submitOrderForm`,
  async (
    orderDetails: PaymentState["orderDetails"],
    { getState, dispatch }
  ) => {
    const { authenticated } = getState().auth
    if (!authenticated) {
      dispatch(setOpen(true))
      dispatch(generateVerificationCode(orderDetails.mobilePhone))
    }
  }
)

export const createOrder = createAsyncAppThunk(
  `${name}/createOrder`,
  async (_, { getState, dispatch }) => {
    const { authenticated } = getState().auth

    const { orderDetails } = getState().payment

    if (!authenticated || !orderDetails) {
      return
    }

    const { selectedBatteryCode, selectedFinishLevelCode } =
      getState().detailing

    const { selectedColorCode } = getState().color
    const { selectedUpholsteryCode } = getState().upholstery

    const { selectedAccessoriesCodes } = getState().accessories

    const selectedData: CreateOrderRequest = {
      finishingLevelCode: selectedFinishLevelCode,
      batteryCode: selectedBatteryCode,
      colorCode: selectedColorCode,
      upholsteryCode: selectedUpholsteryCode,
      carExtraCodes: selectedAccessoriesCodes,
    }
    const { data } = await postEcommOrders(selectedData)
    if (data.errorCode === OUT_OF_STOCK_ERROR_CODE) {
      dispatch(openOutOfStockPopup(true))
      dispatch(logout())
      return ""
    }
    if (data.errorCode) {
      dispatch(setOrderError(data.errorMessage ?? " "))
      dispatch(logout())
      return null
    }
    dispatch(setOrderError(""))
    dispatch(
      putOrder({
        batteryCode: selectedBatteryCode,
        finishingLevelCode: selectedFinishLevelCode,
        colorCode: selectedColorCode,
        upholsteryCode: selectedUpholsteryCode,
        extrasCodes: selectedAccessoriesCodes,
        orderId: data.body.orderId,
        userInfo: orderDetails,
      })
    )
    return data.body.orderId
  }
)

export const putOrder = createAsyncAppThunk(
  `${name}/putOrder`,
  async (requestData: EditOrderRequest, { dispatch }) => {
    const { data } = await putEcommOrders(requestData)

    if (data.errorCode) {
      dispatch(setOrderError(data.errorMessage ?? " "))
      dispatch(logout())
      return null
    }
    dispatch(setOrderError(""))
    dispatch(setPaymentForm(true))
  }
)

export const fetchPaymentUrl = createAsyncAppThunk(
  `${name}/fetchPaymentUrl`,
  async (orderId: string) => {
    return postEcommPaymentRedirectTransaction({
      orderId,
      redirectTransactionRequest: {
        userData1: "",
        userData2: "",
        userData3: "",
        currency: "",
      },
    }).then(extractData)
  }
)

export const setTransaction = createAsyncAppThunk(
  `${name}/setTransaction`,
  async (transaction: TransactionParams, { getState, dispatch }) => {
    if (!transaction) return null

    const {
      payment: { transactionId, orderId },
    } = getState()

    const { data } = await postEcommOrdersComplete({
      orderId: orderId || "",
      transactionId,
      paymentSucceed: transaction.succeed === "true",
      txId: transaction.txId,
      errorCode: transaction.ErrorCode,
      cardToken: transaction.cardToken,
      cardExp: transaction.cardExp,
      personalId: transaction.personalId,
      uniqueId: transaction.uniqueID,
      responseMac: transaction.responseMac,
    })
    if (data.errorCode) {
      dispatch(setOrderError(data.errorMessage ?? " "))
      dispatch(logout())
      return null
    }
    dispatch(setOrderError(""))

    const response = data.body

    response.message = transaction.ErrorText || response.message

    return response
  }
)

export const quitProcess = createAsyncAppThunk(
  `${name}/quitProcess`,
  async (_, { getState }) => {
    const {
      payment: { orderId },
    } = getState()
    return postLeadNewLeftLead({
      orderId,
    }).then(extractData)
  }
)

const payment = createSlice({
  name,
  initialState,
  reducers: {
    setOrderError(
      state,
      { payload }: PayloadAction<PaymentState["orderError"]>
    ) {
      state.orderError = payload
    },
    clearCreditGuardUrl(state) {
      state.creditGuardUrl = ""
    },
    resetTransactionComplete(state) {
      state.transactionComplete = null
    },
    clearOrder(state) {
      state.transactionComplete = null
      state.creditGuardUrl = ""
      state.orderDetails = null
    },
    openOutOfStockPopup(
      state,
      { payload }: PayloadAction<PaymentState["showOutOfStock"]>
    ) {
      state.showOutOfStock = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitOrderForm.pending, (state, { meta }) => {
        state.orderDetails = meta.arg
      })

      .addCase(createOrder.fulfilled, (state, { payload }) => {
        state.orderId = payload
      })

      .addCase(fetchPaymentUrl.fulfilled, (state, { payload }) => {
        state.creditGuardUrl = payload.mpiHostPageUrl
        state.transactionId = payload.transactionId
      })
      .addCase(setTransaction.fulfilled, (state, { payload }) => {
        if (!payload) return
        state.transactionComplete = payload
      })
  },
})

export default payment.reducer

export const {
  actions: {
    setOrderError,
    clearCreditGuardUrl,
    resetTransactionComplete,
    clearOrder,
    openOutOfStockPopup,
  },
} = payment
