import {
  AsyncThunkPayloadCreator,
  combineReducers,
  configureStore,
  createAsyncThunk,
  Reducer,
} from "@reduxjs/toolkit"
import accessoriesReducer from "features/accessoriesAndServices/accessoriesAndServicesSlice"
import authReducer from "features/auth/authSlice"
import colorReducer from "features/color/colorSlice"
import detailingReducer from "features/detailing/detailingSlice"
import paymentReducer from "features/payment/paymentSlice"
import popupReducer from "features/popups/popupSlice"
import showRoomsReducer from "features/showRooms/showRoomsSlice"
import upholsteryReducer from "features/upholstery/upholsterySlice"
import warrantyReducer from "features/warranty/warrantySlice"
import wizardReducer from "features/wizard/wizardSlice"
import layoutReducer from "layout/layoutSlice"
import { createWrapper, HYDRATE } from "next-redux-wrapper"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"

const combinedReducer = combineReducers({
  layout: layoutReducer,
  auth: authReducer,
  wizard: wizardReducer,
  warranty: warrantyReducer,
  detailing: detailingReducer,
  color: colorReducer,
  accessories: accessoriesReducer,
  upholstery: upholsteryReducer,
  payment: paymentReducer,
  showRooms: showRoomsReducer,
  popup: popupReducer,
})

export type RootState = ReturnType<typeof combinedReducer>

const reducer: Reducer<RootState> = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...(action.payload as RootState), // apply delta from hydration
    }

    // Preserve layout, auth, and showRooms states
    if (state) {
      nextState.layout = state.layout
      nextState.auth = state.auth
      nextState.showRooms = state.showRooms
    }

    return nextState
  }

  return combinedReducer(state, action)
}

const makeStore = () => configureStore({ reducer })

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore["getState"]>

export const wrapper = createWrapper<AppStore>(makeStore)

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export type AppDispatch = ReturnType<typeof makeStore>["dispatch"]
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type ThunkApiConfig = {
  state: RootState
  rejectValue: { code: number; message: string }
}
export function createAsyncAppThunk<Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>
) {
  return createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(
    typePrefix,
    payloadCreator
  )
}
