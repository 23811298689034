import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  extractData,
  getArticlesAllFriendlyNames,
  getMenusGetMenuByTypeId,
  getSettingsAll,
} from "api"
import { createAsyncAppThunk } from "store"
import { MenusResponse } from "./../api/generated/GeelyInterfaces"
import { ContactType } from "./contact/ContactForm"

const LOGO_ALT_TEXT_KEY = "LogoAlt"

type ContactDataType = {
  contactType: ContactType | string
  remarks?: string
  leadLegalIsRequired?: boolean
  leadLegalCheckBox?: boolean
  leadLegalText?: string
}

export type LayoutState = {
  drawerOpen: boolean
  popUpOpen: boolean
  purchaseModelId: string | null
  articles: any[]
  logoAlt: string
  contactData: ContactDataType | null
  sideMenuDataItems: MenusResponse
  footerMenuDataItems: MenusResponse
  headerMenuDataItems: MenusResponse
}

const initialState: LayoutState = {
  drawerOpen: false,
  popUpOpen: false,
  purchaseModelId: null,
  articles: [],
  logoAlt: "",
  contactData: null,
  sideMenuDataItems: null,
  footerMenuDataItems: null,
  headerMenuDataItems: null,
}

export const fetchArticles = createAsyncAppThunk(
  `layout/fetchArticles`,
  async () => {
    const body = await getArticlesAllFriendlyNames().then(extractData)
    if (!body) {
      return []
    }
    return body
  }
)

export const fetchLogoAlt = createAsyncAppThunk(
  `layout/fetchLogoAlt`,
  async () => {
    const body = await getSettingsAll().then(extractData)
    const altTextItem = body?.find((item) => item.key === LOGO_ALT_TEXT_KEY)
    return altTextItem?.value ?? ""
  }
)
export const fetchMenus = createAsyncAppThunk(`layout/fetchMenus`, async () => {
  const [sideMenuItems, headerMenuItems, footerMenuItems] = await Promise.all([
    getMenusGetMenuByTypeId(1).then(extractData),
    getMenusGetMenuByTypeId(2).then(extractData),
    getMenusGetMenuByTypeId(3).then(extractData),
  ])
  return { sideMenuItems, headerMenuItems, footerMenuItems }
})

const layout = createSlice({
  name: "layout",
  initialState: initialState,
  reducers: {
    toggleDrawer(state) {
      state.drawerOpen = !state.drawerOpen
    },
    setPurchaseModelId(
      state,
      { payload }: PayloadAction<LayoutState["purchaseModelId"]>
    ) {
      state.purchaseModelId = payload
    },
    toggleContactPopUp(
      state,
      { payload }: PayloadAction<LayoutState["contactData"]>
    ) {
      state.popUpOpen = !state.popUpOpen
      state.contactData = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticles.fulfilled, (state, { payload }) => {
        state.articles = payload
      })
      .addCase(fetchLogoAlt.fulfilled, (state, { payload }) => {
        state.logoAlt = payload
      })
      .addCase(fetchMenus.fulfilled, (state, { payload }) => {
        const { sideMenuItems, headerMenuItems, footerMenuItems } = payload
        state.sideMenuDataItems = sideMenuItems
        state.headerMenuDataItems = headerMenuItems
        state.footerMenuDataItems = footerMenuItems
      })
  },
})

export const { toggleDrawer, setPurchaseModelId, toggleContactPopUp } =
  layout.actions

export default layout.reducer
