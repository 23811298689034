import { getModelNameEnglish } from "./modelConsts"

const PURCHASE_MODEL_ID = process.env.PURCHASE_MODEL_ID
// const PURCHASE_MODEL_BOOL = PURCHASE_MODEL_ID === undefined ? true : false

type NavigationLinksType = {
  [key: string]: {
    title: string
    link: string
    blank?: true
    isContact?: boolean
    isCms?: boolean
  }
}

const HeaderNavigationLinks: NavigationLinksType = {
  Geometry: {
    title: getModelNameEnglish().toUpperCase(),
    link: "/",
  },
  Purchase: {
    title: "רכישה אונליין",
    link: `/${PURCHASE_MODEL_ID}`,
  },
  Calculator: {
    title: "מחשבון חסכון",
    link: "/calculator",
  },
  FinanceCalculator: {
    title: "מחשבון מימון",
    link: "/finance",
  },
  FinanceLeasing: {
    title: "מחשבון ליסינג",
    link: "/leasing",
  },
  ServiceCenters: {
    title: "אולמות תצוגה ומרכזי שירות",
    link: "/agencies",
  },
  ChargingStations: {
    title: "עמדות טעינה",
    link: "/stations",
  },
  Faq: {
    title: "שאלות תשובות",
    link: "/faq",
  },
}

const TopSideMenuLinks: NavigationLinksType = {
  MultimediaBook: {
    title: "ספר מולטימדיה",
    link: "multimediaGuideLink",
    blank: true,
    isCms: true,
  },
  ShortGuide: {
    title: "מדריך מקוצר",
    link: "shortGuideLink",
    blank: true,
    isCms: true,
  },
  WarrantyBooklet: {
    title: "חוברת אחריות",
    link: "warrantyGuideLink",
    blank: true,
    isCms: true,
  },
  ExtendedWarranty: {
    title: "תקנון הארכת אחריות לסוללה",
    link: "extendedWarrantyLink",
    blank: true,
    isCms: true,
  },
  DownloadSpecifications: {
    title: "מפרט טכני מקוצר",
    link: "carSpecsLink",
    blank: true,
    isCms: true,
  },
  DownloadCatalog: {
    title: "קטלוג",
    link: "catalogLink",
    blank: true,
    isCms: true,
  },
  DownloadDriverManual: {
    title: "ספר רכב",
    link: "driverManualLink",
    blank: true,
    isCms: true,
  },
  DownloadSparePartsLink: {
    title: "Spare Part Catalog",
    link: "sparePartsLink",
    blank: true,
    isCms: true,
  },
  DownloadWorkshopManualLink: {
    title: "Workshop Manual",
    link: "workshopManualLink",
    blank: true,
    isCms: true,
  },
  DownloadTechnicalInfoLink: {
    title: "Maintenance and technical information",
    link: "technicalInfoLink",
    blank: true,
    isCms: true,
  },
}
const MiddleSideMenuLinks: NavigationLinksType = {
  BuyOnline: {
    title: "רכישה אונליין",
    link: `/${PURCHASE_MODEL_ID}`,
  },
  Calculator: {
    title: "מחשבון חסכון",
    link: "/calculator",
  },
  FinanceCalculator: {
    title: "מחשבון מימון",
    link: "/finance",
  },
  FinanceLeasing: {
    title: "מחשבון ליסינג",
    link: "/leasing",
  },
  Showrooms: {
    title: "אולמות תצוגה ומרכזי שירות",
    link: "/agencies",
  },
  ChargingStations: {
    title: "עמדות טעינה",
    link: "/stations",
  },
  Faq: {
    title: "שאלות תשובות",
    link: "/faq",
  },
  Contact: {
    title: "צרו קשר",
    link: "",
    isContact: true,
  },
  insuranceUrl: {
    title: "ביטוח רכב אונליין",
    link: "insuranceUrl",
    blank: true,
    isCms: true,
  },
}
const BottomSideMenuLinks: NavigationLinksType = {
  TermsOfUse: {
    title: "תנאי שימוש",
    link: "termsOfUseLink",
    blank: true,
    isCms: true,
  },
}

const FooterNavigationLinks: NavigationLinksType = {
  TermsOfUse: {
    title: "תנאי שימוש",
    link: "termsOfUseLink",
    blank: true,
    isCms: true,
  },
  PrivacyPolicy: {
    title: "מדיניות הפרטיות",
    link: "privacyPolicyLink",
    blank: true,
    isCms: true,
  },
  GeneralTermsAgreement: {
    title: "הסכם הזמנת רכב",
    link: "carBookingAgreementLink",
    blank: true,
    isCms: true,
  },
  DownloadWarranty: {
    title: "כתב אחריות GEELY",
    link: "warrantyLink",
    blank: true,
    isCms: true,
  },
  AccessibilityDeclaration: {
    title: "הצהרת נגישות",
    link: "/accessibilityDeclaration.pdf",
    blank: true,
    isCms: false,
  },
  Contact: {
    title: "צרו קשר",
    link: "/",
    isContact: true,
  },
}

const PurchaseFooterNavigationLinks: NavigationLinksType = {
  TermsOfUse: {
    title: "תנאי שימוש",
    link: "termsOfUseLink",
    blank: true,
    isCms: true,
  },
  PrivacyPolicy: {
    title: "מדיניות פרטיות",
    link: "privacyPolicyLink",
    blank: true,
    isCms: true,
  },
  GeneralTermsAgreement: {
    title: "הסכם הזמנת רכב",
    link: "carBookingAgreementLink",
    blank: true,
    isCms: true,
  },
  DownloadSpecifications: {
    title: "הורדת קטלוג",
    link: "catalogLink",
    blank: true,
    isCms: true,
  },
  Contact: {
    title: "צרו קשר",
    link: "/",
    isContact: true,
  },
}

export {
  HeaderNavigationLinks,
  TopSideMenuLinks,
  BottomSideMenuLinks,
  FooterNavigationLinks,
  PurchaseFooterNavigationLinks,
  MiddleSideMenuLinks,
}
