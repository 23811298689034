const AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY =
  process.env.AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY

export const initApplicationInsights = () => {
  if (!AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY) {
    return
  }
  import("@microsoft/applicationinsights-web").then(
    ({ ApplicationInsights }) => {
      window.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
        },
      })
      window.appInsights.loadAppInsights()
      window.appInsights.trackPageView()
    }
  )
}
