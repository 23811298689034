import { createSlice } from "@reduxjs/toolkit"
import { extractData, getAppMetaDataShowrooms, ShowRooms } from "api"
import { createAsyncAppThunk } from "store"

const name = "showRooms"

type ShowRoomsState = {
  showRooms: ShowRooms[]
}

const initialState: ShowRoomsState = {
  showRooms: [],
}

export const fetchShowRooms = createAsyncAppThunk(
  `${name}/fetchShowRooms`,
  async () => {
    const body = await getAppMetaDataShowrooms().then(extractData)
    if (!body) {
      return []
    }
    return body
  }
)

const showRooms = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchShowRooms.fulfilled, (state, { payload }) => {
      state.showRooms = payload
    })
  },
})

export default showRooms.reducer
