/** THIS FILE IS AUTOMATICALLY GENERATED FROM : https://service.geely.co.il/swagger/v1/swagger.json **/

import axios from "../axios"
import * as Geely from "./GeelyInterfaces"

export const postAccountVerifyUser = (data: Geely.VerifyUserRequest) =>
  axios.post<Geely.VerifyUserResponseResponseWrapper>(
    `/api/account/verifyUser`,
    data
  )

export const getAccountGenerateVerificationCode = (
  mobilePhone: string,
  toSendSms: boolean
) =>
  axios.get<Geely.GenerateVerificationCodeResponseResponseWrapper>(
    `/api/account/generateVerificationCode`,
    { params: { mobilePhone, toSendSms } }
  )

export const postAccountRegister = (data: Geely.ShortRegistrationRequest) =>
  axios.post<Geely.UserRegistrationResponseResponseWrapper>(
    `/api/account/register`,
    data
  )

export const getAccountGetUserInfo = () =>
  axios.get<Geely.UserInfoResponseResponseWrapper>(`/api/account/getUserInfo`)

export const postAccountLogout = () =>
  axios.post<Geely.BooleanResponseWrapper>(`/api/account/logout`)

export const getAppMetaDataShowrooms = () =>
  axios.get<Geely.ShowRoomsDtoListResponseWrapper>(`/api/appMetaData/showrooms`)

export const getArticlesGetById = (id: number /* int32 */) =>
  axios.get<Geely.ArticleDtoResponseWrapper>(`/api/articles/getById`, {
    params: { id },
  })

export const getArticlesName = (name: string) =>
  axios.get<Geely.ArticleDtoResponseWrapper>(`/api/articles/${name}`)

export const getArticlesAllFriendlyNames = () =>
  axios.get<Geely.ArticleNamesResponseListResponseWrapper>(
    `/api/articles/allFriendlyNames`
  )

export const getArticlesLobbyArticles = () =>
  axios.get<Geely.ArticleLobbyDtoListResponseWrapper>(
    `/api/articles/lobbyArticles`
  )

export const getBannerGetById = (key: string) =>
  axios.get<Geely.MainBannerDtoResponseWrapper>(`/api/Banner/getById`, {
    params: { key },
  })

export const postCacheClearCache = (data: Geely.CacheKey) =>
  axios.post<Geely.BooleanResponseWrapper>(`/api/cache/clearCache`, data)

export const postCacheClearAllCache = () =>
  axios.post<Geely.BooleanResponseWrapper>(`/api/cache/clearAllCache`)

export const getCalculatorFundingCalculator = (carModel: number /* int32 */) =>
  axios.get<Geely.FundingCalculatorDtoResponseWrapper>(
    `/api/calculator/fundingCalculator`,
    { params: { carModel } }
  )

export const getCalculatorLeasingCalculator = (carModel: number /* int32 */) =>
  axios.get<Geely.LeasingCalculatorGeneralDtoResponseWrapper>(
    `/api/calculator/leasingCalculator`,
    { params: { carModel } }
  )

export const getChargingStationsAll = () =>
  axios.get<Geely.GeelyChargingStationDtoICollectionResponseWrapper>(
    `/api/chargingStations/all`
  )

export const getEcommColors = (
  batteryCode: string,
  finishingLevelCode: string
) =>
  axios.get<Geely.ColorDtoUnionComponentDtoListResponseWrapper>(
    `/api/ecomm/colors`,
    { params: { batteryCode, finishingLevelCode } }
  )

export const postContactUs = (data: Geely.Contact) =>
  axios.post<Geely.BooleanResponseWrapper>(`/api/ContactUs`, data)

export const postContactUsNewLead = (data: Geely.ContactNewLeadForm) =>
  axios.post<Geely.ContactNewLeadResponseResponseWrapper>(
    `/api/ContactUs/NewLead`,
    data
  )

export const postDelekIntegratedSyncDelekEcommerceData = () =>
  axios.post<Geely.BooleanResponseWrapper>(
    `/api/delekIntegrated/syncDelekEcommerceData`
  )

export const getEcho = () => axios.get<Geely.StringResponseWrapper>(`/api/echo`)

export const getExtras = (batteryCode: string, finishingLevelCode: string) =>
  axios.get<Geely.CarExtraItemDtoUnionComponentDtoListResponseWrapper>(
    `/api/extras`,
    { params: { batteryCode, finishingLevelCode } }
  )

export const getExtrasStockBypass = (
  batteryCode: string,
  finishingLevelCode: string
) =>
  axios.get<Geely.CarExtraItemDtoUnionComponentDtoListResponseWrapper>(
    `/api/extras/stockBypass`,
    { params: { batteryCode, finishingLevelCode } }
  )

export const getFaqAll = () =>
  axios.get<Geely.QuestionAndAnswerDtoICollectionResponseWrapper>(
    `/api/faq/all`
  )

export const getFilesLobbyFiles = () =>
  axios.get<Geely.FileLobbyDtoListResponseWrapper>(`/api/files/lobbyFiles`)

export const getEcommBatteriesCode = (code: string) =>
  axios.get<Geely.FinishingLevelDtoResponseWrapper>(
    `/api/ecomm/batteries/${code}`
  )

export const getEcommBatteries = (modelId: number /* int32 */) =>
  axios.get<Geely.FinishingLevelDtoUnionComponentDtoListResponseWrapper>(
    `/api/ecomm/batteries`,
    { params: { modelId } }
  )

export const getEcommBatteriesBatteryByRange = (modelId: number /* int32 */) =>
  axios.get<Geely.FinishingLevelDtoUnionComponentDtoListResponseWrapper>(
    `/api/ecomm/batteries/batteryByRange`,
    { params: { modelId } }
  )

export const getGeelyJsonDynamicPagesGetPageByType = (
  pageType: number /* int32 */
) =>
  axios.get<Geely.GeelyJsonDynamicPages_AppPageContentDtoResponseWrapper>(
    `/api/GeelyJsonDynamicPages/GetPageByType`,
    { params: { pageType } }
  )

export const getGeelyJsonDynamicPagesGetOrderCarServicePage = () =>
  axios.get<Geely.GeelyJsonDynamicPages_OrderCarServicePageContentDtoResponseWrapper>(
    `/api/GeelyJsonDynamicPages/GetOrderCarServicePage`
  )

export const getGeelyJsonDynamicPagesGetGeelySelectPage = () =>
  axios.get<Geely.GeelyJsonDynamicPages_GeelySelectPageDtoResponseWrapper>(
    `/api/GeelyJsonDynamicPages/GetGeelySelectPage`
  )

export const getGeelyModelsId = (id: string) =>
  axios.get<Geely.GeelyCarModelResponseResponseWrapper>(
    `/api/GeelyModels/${id}`
  )

export const getGeelyModelsLinksId = (id: string) =>
  axios.get<Geely.CarDynamicLinksResponseResponseWrapper>(
    `/api/GeelyModels/links/${id}`
  )

export const getGeelySavingCalc = () =>
  axios.get<Geely.SavingCalculatorDtoListResponseWrapper>(
    `/api/GeelySavingCalc`
  )

export const getGeelytemplatepagesGeelyPage = (pageType: number /* int32 */) =>
  axios.get<Geely.GeelyTemplatePagesDtoResponseWrapper>(
    `/api/geelytemplatepages/geelyPage`,
    { params: { pageType } }
  )

export const getSettingsAll = () =>
  axios.get<Geely.GeneralSettingDtoListResponseWrapper>(`/api/settings/all`)

export const postLeadNewLeftLead = (data: Geely.LeadLeftRequest) =>
  axios.post<Geely.LeadLeftResponseResponseWrapper>(
    `/api/Lead/NewLeftLead`,
    data
  )

export const getLobbyLobbyCategories = () =>
  axios.get<Geely.LobbyCategoriesDtoListResponseWrapper>(
    `/api/lobby/lobbyCategories`
  )

export const getLobbyLobbyPages = (typeId: number /* int32 */) =>
  axios.get<Geely.LobbyPagesDtoResponseWrapper>(`/api/lobby/lobbyPages`, {
    params: { typeId },
  })

export const getLobbypagesGeelyJobsPage = () =>
  axios.get<Geely.GeelyJobsDtoListResponseWrapper>(
    `/api/lobbypages/geelyJobsPage`
  )

export const getLobbypagesGeelyServicesLobbyPage = () =>
  axios.get<Geely.GeelyServicesPageLobbyDtoListResponseWrapper>(
    `/api/lobbypages/geelyServicesLobbyPage`
  )

export const getLobbypagesGeelyServiceByFriendlyName = (name: string) =>
  axios.get<Geely.GeelyServicesPageDtoResponseWrapper>(
    `/api/lobbypages/geelyServiceByFriendlyName`,
    { params: { name } }
  )

export const getMenusGetMenuByTypeId = (menuTypeId: number /* int32 */) =>
  axios.get<Geely.MenusResponseResponseWrapper>(`/api/menus/getMenuByTypeId`, {
    params: { menuTypeId },
  })

export const getEcommModelsId = (id: string) =>
  axios.get<Geely.CarModelDtoUnionComponentCarModelResponseResponseWrapper>(
    `/api/ecomm/models/${id}`
  )

export const getEcommModels = () =>
  axios.get<Geely.CarModelDtoUnionComponentDtoListResponseWrapper>(
    `/api/ecomm/models`
  )

export const getEcommOrdersId = (id: string) =>
  axios.get<Geely.OrderStatusResponseResponseWrapper>(`/api/ecomm/orders/${id}`)

export const postEcommOrders = (data: Geely.CreateOrderRequest) =>
  axios.post<Geely.CreateOrderResponseResponseWrapper>(
    `/api/ecomm/orders`,
    data
  )

export const putEcommOrders = (data: Geely.EditOrderRequest) =>
  axios.put<Geely.CreateOrderResponseResponseWrapper>(`/api/ecomm/orders`, data)

export const postEcommOrdersComplete = (data: Geely.CompletePaymentRequest) =>
  axios.post<Geely.CompletePaymentResponseResponseWrapper>(
    `/api/ecomm/orders/complete`,
    data
  )

export const postEcommOrdersCheckOrderId = (data: Geely.OrderCheckIdRequest) =>
  axios.post<Geely.OrderCheckIdResponseResponseWrapper>(
    `/api/ecomm/orders/checkOrderId`,
    data
  )

export const postEcommPaymentRedirectTransaction = (
  data: Geely.PaymentRedirectRequest
) =>
  axios.post<Geely.CreditGuardRedirectTransactionDtoResponseWrapper>(
    `/api/ecomm/payment/redirectTransaction`,
    data
  )

export const getPricesLicensingFee = (batteryCode: string) =>
  axios.get<Geely.LicensingFeeResponseResponseWrapper>(
    `/api/prices/licensingFee`,
    { params: { batteryCode } }
  )

export const getPrices = (
  batteryCode: string,
  colorCode: string,
  upholsteryCode: string,
  finishingLevelCode: string,
  carExtraCodes: string[]
) =>
  axios.get<Geely.PriceItemDtoResponseWrapper>(`/api/prices`, {
    params: {
      batteryCode,
      colorCode,
      upholsteryCode,
      finishingLevelCode,
      carExtraCodes,
    },
  })

export const postRecallNewRecall = (data: Geely.NewRecallRequest) =>
  axios.post<Geely.RecallResponseResponseWrapper>(`/api/Recall/NewRecall`, data)

export const getSeoKey = (key: string) =>
  axios.get<Geely.SeoMetaDataDtoResponseWrapper>(`/api/seo/${key}`)

export const getEcommUpholsteries = (
  batteryCode: string,
  colorCode: string,
  finishingLevelCode: string
) =>
  axios.get<Geely.UpholsteryDtoUnionComponentDtoListResponseWrapper>(
    `/api/ecomm/upholsteries`,
    { params: { batteryCode, colorCode, finishingLevelCode } }
  )

export const getVideosLobbyVideos = (modelName: string) =>
  axios.get<Geely.VideoLobbyDtoListResponseWrapper>(`/api/videos/lobbyVideos`, {
    params: { modelName },
  })
