import { Canceler } from "axios"
import qs from "qs"
import axios, { CancelToken } from "./axios"
import { PriceItemDtoResponseWrapper } from "./generated/GeelyInterfaces"

export let cancelGetPricesRequest: Canceler | undefined

export const getPricesWithCancellation = (
  batteryCode: string,
  colorCode: string,
  upholsteryCode: string,
  finishingLevelCode: string,
  carExtraCodes: string[]
) =>
  axios.get<PriceItemDtoResponseWrapper>("/api/prices", {
    params: {
      batteryCode,
      colorCode,
      upholsteryCode,
      finishingLevelCode,
      carExtraCodes,
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
    cancelToken: new CancelToken((c) => {
      cancelGetPricesRequest = c
    })
  })
