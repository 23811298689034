import { createSlice } from "@reduxjs/toolkit"
import {
  extractData,
  FinishingLevelDtoUnionComponent,
  GeelyFinishingLevel,
  getEcommBatteriesBatteryByRange,
} from "api"
import { fetchExtras } from "features/accessoriesAndServices/accessoriesAndServicesSlice"
import { fetchColors } from "features/color/colorSlice"
import {
  updateBasicPrice,
  updateLicensingFee,
  updatePriceAndURL,
} from "features/wizard/wizardSlice"
import { createAsyncAppThunk } from "store"

const name = "detailing"

export type DetailingState = {
  batteries: FinishingLevelDtoUnionComponent[]
  selectedFinishLevelCode: GeelyFinishingLevel["code"]
  selectedBatteryCode: string
}

const initialState: DetailingState = {
  batteries: [],
  selectedFinishLevelCode: "",
  selectedBatteryCode: "",
}

export const fetchBatteries = createAsyncAppThunk(
  `${name}/fetchBatteries`,
  async (model: string) => {
    return getEcommBatteriesBatteryByRange(Number(model)).then(extractData)
  }
)

export const setSelectedBattery = createAsyncAppThunk(
  `${name}/setSelectedBattery`,
  (batteryCode: string, { getState, dispatch }) => {
    const { batteries } = getState().detailing

    const [battery] = batteries.filter(
      (battery) => battery.details.code === batteryCode
    )
    dispatch(setSelectedFinishLevel(battery?.details.finishingLevels[0].code))
  }
)

export const setSelectedFinishLevel = createAsyncAppThunk(
  `${name}/setSelectedFinishLevel`,
  async (finishLevelCode: string, { getState, dispatch }) => {
    const { selectedBatteryCode } = getState().detailing

    await Promise.all([
      dispatch(updatePriceAndURL()),
      dispatch(
        fetchColors({
          batteryCode: selectedBatteryCode,
          finishingLevelCode: finishLevelCode,
        })
      ),
      dispatch(fetchExtras()),

      dispatch(updateLicensingFee(selectedBatteryCode)),
      dispatch(updateBasicPrice()),
    ])
  }
)

const detailing = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBatteries.fulfilled, (state, { payload }) => {
        state.batteries = payload
      })
      .addCase(setSelectedBattery.pending, (state, { meta }) => {
        state.selectedBatteryCode = meta.arg
      })
      .addCase(setSelectedFinishLevel.pending, (state, { meta }) => {
        state.selectedFinishLevelCode = meta.arg
      })
  },
})

export default detailing.reducer
