import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  clearToken as clearAPIToken,
  extractData,
  getAccountGenerateVerificationCode,
  getAccountGetUserInfo,
  postAccountLogout,
  postAccountRegister,
  postAccountVerifyUser,
  setToken,
  UserInfoResponse,
  VerifyUserRequest,
  VerifyUserResponse
} from "api"
import { AxiosError } from "axios"
import { trackLoggedIn } from "common/analytics"
import { fetchBatteries } from "features/detailing/detailingSlice"
import { createAsyncAppThunk } from "store"
import * as authRepository from "./authRepository"

const name = "auth"

export type AuthState = {
  open: boolean
  step: "Phone" | "OTP"
  userInfo: UserInfoResponse | null
  temporaryToken: VerifyUserResponse["verificationToken"]
  temporaryPhone: VerifyUserResponse["verifiedMobilePhone"]
  error?: string
  loading: boolean
  authenticated: boolean
  initialized: boolean
  allowSendingCodeAgain: boolean
  codeExpirationInSeconds: number | null
  clientLoggedIn: boolean
}

const initialState: AuthState = {
  open: false,
  step: "OTP",
  userInfo: null,
  temporaryToken: "",
  temporaryPhone: "",
  error: "",
  loading: false,
  authenticated: false,
  initialized: false,
  allowSendingCodeAgain: false,
  codeExpirationInSeconds: null,
  clientLoggedIn: false,
}

const clearToken = () => {
  clearAPIToken()
  authRepository.clearToken()
}

export const setUserInfo = createAsyncAppThunk(
  `${name}/setUserInfo`,
  async (_userInfo: AuthState["userInfo"], { getState, dispatch }) => {
    const {
      wizard: { model },
      auth: { initialized },
    } = getState()

    if (_userInfo) {
      dispatch(setClientLoggedIn(true))
    }
    if (model && initialized) {
      // Re-fetch detailings to have updated prices for everything
      const modelId = model.id.toString()
      await dispatch(fetchBatteries(modelId))
    }
  }
)

export const generateVerificationCode = createAsyncAppThunk(
  `${name}/generateVerificationCode`,
  async (phone: string | null, { getState }) => {
    const body = await getAccountGenerateVerificationCode(
      phone || getState().auth.temporaryPhone,
      true
    ).then(extractData)
    return body.totalTimeoutInSeconds
  }
)

export const verifyUser = createAsyncAppThunk(
  `${name}/verifyUser`,
  async (
    verificationCode: VerifyUserRequest["verificationCode"],
    { getState, rejectWithValue, dispatch }
  ) => {
    const { data } = await postAccountVerifyUser({
      mobilePhone: getState().auth.temporaryPhone,
      verificationCode,
      code: null,
    })

    if (data.errorCode !== 0) {
      return rejectWithValue({
        code: data.errorCode,
        message: data.errorMessage ?? "",
      })
    }

    if (data.body.userInfo) {
      trackLoggedIn()

      await dispatch(setUserInfo(data.body.userInfo))
    } else {
      dispatch(
        register({
          temporaryPhone: data.body.verifiedMobilePhone,
          temporaryToken: data.body.verificationToken,
        })
      )
    }
    return data.body
  }
)

export const register = createAsyncAppThunk(
  `${name}/register`,
  async (
    {
      temporaryPhone,
      temporaryToken,
    }: { temporaryPhone: string; temporaryToken: string },
    { getState, dispatch }
  ) => {
    const { orderDetails } = getState().payment

    const body = await postAccountRegister({
      mobilePhone: temporaryPhone,
      firstName: orderDetails.firstName,
      lastName: orderDetails.lastName,
      verifyToken: temporaryToken,
      isMailing: orderDetails.isMailing,
    }).then(extractData)

    trackLoggedIn()

    await dispatch(setUserInfo(body.userInfo))
  }
)

export const logout = createAsyncAppThunk(
  `${name}/logout`,
  async (_, { dispatch }) => {

    await Promise.allSettled([postAccountLogout(), dispatch(setUserInfo(null))])
  }
)

export const initAuth = createAsyncAppThunk(
  `${name}/initAuth`,
  async (token: string | null, { dispatch }) => {
    if (!token) return

    setToken(token)

    try {
      const body = await getAccountGetUserInfo().then(extractData)
      await dispatch(setUserInfo(body))
    } catch (error) {
      const { response } = error as AxiosError
      if (response?.status === 401) clearToken()
    }
  }
)

const auth = createSlice({
  name,
  initialState,
  reducers: {
    setOpen(state, { payload }: PayloadAction<AuthState["open"]>) {
      state.open = payload
    },
    backToPhoneStep(state) {
      state.step = "Phone"
    },
    setAllowSendingCodeAgain(state) {
      state.allowSendingCodeAgain = true
    },
    setClientLoggedIn(
      state,
      { payload }: PayloadAction<AuthState["clientLoggedIn"]>
    ) {
      state.clientLoggedIn = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUserInfo.pending, (state, { meta }) => {
        const userInfo = meta.arg

        if (userInfo) {
          if (userInfo.accessToken) {
            setToken(userInfo.accessToken)
          }

          state.authenticated = true
          state.temporaryPhone = ""
        } else {
          clearToken()

          state.authenticated = false
        }

        state.userInfo = userInfo
        state.open = false
        state.loading = false
      })
      .addCase(generateVerificationCode.pending, (state, { meta }) => {
        state.loading = true
        if (meta.arg) state.temporaryPhone = meta.arg
        state.codeExpirationInSeconds = null
      })
      .addCase(generateVerificationCode.fulfilled, (state, { payload }) => {
        state.step = "OTP"
        state.loading = false
        state.allowSendingCodeAgain = false
        state.codeExpirationInSeconds = payload
      })
      .addCase(verifyUser.pending, (state) => {
        state.loading = true
        state.error = ""
      })
      .addCase(verifyUser.fulfilled, (state, { payload }) => {
        if (!payload.userInfo) {
          state.temporaryToken = payload.verificationToken
          state.temporaryPhone = payload.verifiedMobilePhone
        }

        state.loading = false
      })
      .addCase(verifyUser.rejected, (state, { payload, error }) => {
        if (payload) {
          state.error = payload.code === 50001 ? "קוד שגוי" : payload.message
        } else {
          state.error = error.message
        }

        state.loading = false
      })
      .addCase(register.pending, (state) => {
        state.loading = true
      })
      .addCase(logout.fulfilled, (state) => {
        state.step = "Phone"
      })
      .addCase(initAuth.fulfilled, (state) => {
        state.initialized = true
      })
  },
})

export const {
  setOpen,
  backToPhoneStep,
  setAllowSendingCodeAgain,
  setClientLoggedIn,
} = auth.actions

export default auth.reducer
