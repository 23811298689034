import { Button, Grid, Typography } from "@material-ui/core"
import { extractData, postContactUsNewLead } from "api"
import FormikCheckboxField from "common/FormikCheckboxField"
import { useIsDesktop } from "common/MediaQueries"
import NextLink from "common/NextLink"
import UseFocusFirstInvalidField from "common/UseFocusFirstInvalidField"
import { trackCallMeBack, trackLeadSuccess } from "common/analytics"
import { parseCookie } from "common/utils/parseCookie"
import { Field, Form, Formik, FormikProps } from "formik"
import { TextField } from "formik-material-ui"
import { createRef, useMemo, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useTypedSelector } from "store"
import styled from "styled-components"
import * as yup from "yup"
import ContactFormSchema from "./ContactFormSchema"
import ShowRoomSelectContextUs from "./ShowRoomSelectContextUs"
import getUtmParams from "./getUtmParams"

const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY
if (!RECAPTCHA_KEY) {
  throw new Error("Please provide the RECAPTCHA_KEY environment variable.")
}

export enum ContactType {
  finance = "צור קשר – מחשבון מימון",
  leasing = "צור קשר – מחשבון ליסינג",
  onlinePurchase = "צור קשר - לרכישה אונליין",
  geelySelect = "צור קשר - Geely Select",
}

const defaultFormType = "צור קשר - כללי באתר"
const defaultMainDirected = "Geely website"

const StyledForm = styled(Form)`
  text-align: right;
`

const StyledTextField = styled(TextField)`
  .MuiInputLabel-root {
    font-size: 14px;
    color: ${(p) => p.theme.colors.midnight};
  }
  .MuiInput-input {
    border: solid 1px ${(p) => p.theme.colors.coolGreyTwo};
  }
`

const StyledButton = styled(Button)`
  margin-top: 22px;
  margin-bottom: 0;
  margin-left: 0;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  color: ${(p) => p.theme.colors.white};
  background-image: linear-gradient(
    to right,
    ${(p) => p.theme.colors.mainBlue},
    ${(p) => p.theme.colors.mainPurple}
  );
  ${(p) => p.theme.breakpoints.up("md")} {
    height: 42px;
  }
`
const StyledLegal = styled(Typography)`
  margin-top: 20px;
  font-size: 14px;
  color: ${(p) => p.theme.colors.greyishBrown};
  ${(p) => p.theme.breakpoints.down("sm")} {
    font-size: 13px;
  }
`

const StyledLegalLink = styled(NextLink)`
  a {
    margin-right: 10px;
    margin-left: 10px;
    color: ${(p) => p.theme.colors.greyishBrown};
  }
`

const StyledField = styled(Field)`
  textarea {
    height: 67px;
    ${(p) => p.theme.breakpoints.up("sm")} {
      height: 87px;
    }
  }
`

const _initialValues: yup.InferType<
  typeof ContactFormSchema.schemaLeadNotRequired
> = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  agency: "",
  agreeMarketingOffers: false,
  captchaCode: "",
  remarks: "",
  leadLegalCheckBox: false,
}

type ContactFormProps = {
  onSubmit: (success: boolean) => void
}

const ContactForm = ({ onSubmit }: ContactFormProps) => {
  const { contactData } = useTypedSelector((state) => state.layout)
  const isDesktop = useIsDesktop()
  const { showRooms } = useTypedSelector((state) => state.showRooms)
  const bagRef = useRef<FormikProps<typeof initialValues>>()
  const reCaptchaRef = createRef<ReCAPTCHA>()
  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)
  const phoneRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const agreeMarketingOffersRef = useRef<HTMLButtonElement>(null)
  const leadLegalCheckBoxRef = useRef<HTMLButtonElement>(null)
  const showroomsRef = useRef<HTMLSelectElement>(null)
  const remarksRef = useRef<HTMLTextAreaElement>(null)
  const [initialValues, setInitialValues] =
    useState<yup.InferType<typeof ContactFormSchema.schemaLeadNotRequired>>(
      _initialValues
    )

  const fieldNameInputRefMap = useMemo(
    () => ({
      firstName: firstNameRef,
      lastName: lastNameRef,
      phone: phoneRef,
      email: emailRef,
      agency: showroomsRef,
      agreeMarketingOffer: agreeMarketingOffersRef,
      captchaCode: reCaptchaRef,
      remarks: remarksRef,
      leadLegalCheckBox: leadLegalCheckBoxRef,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const validationSchema = useMemo(() => {
    return contactData?.leadLegalCheckBox
      ? contactData.leadLegalIsRequired
        ? ContactFormSchema.schemaLeadRequired
        : ContactFormSchema.schemaLeadNotRequired
      : ContactFormSchema.schemaNoLead
  }, [contactData])

  return (
    <Formik
      innerRef={bagRef as any}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values) => {
        console.log("submit")

        reCaptchaRef.current?.reset()
        const recaptchaResponse = await reCaptchaRef.current?.executeAsync()
        const {
          medium,
          source,
          advertiserGroupId,
          advertisingId,
          url,
          campaignId,
          campaign,
          gclid,
        } = getUtmParams()

        const agency = showRooms.find(
          (item) => item.id.toString() === values.agency
        )

        const remarks =
          values.remarks +
          (contactData?.remarks ? `\n${contactData?.remarks}` : "")
        const body = await postContactUsNewLead({
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          phone: values.phone,
          email: values.email.trim(),
          agency: agency.agencyCode,
          subject: "NewCar",
          isNewsletter: values.agreeMarketingOffers,
          gRecaptchaResponse: recaptchaResponse,
          remark: remarks,
          mainDirected: medium || defaultMainDirected,
          subDirected: source,
          formType: contactData?.contactType || defaultFormType,
          digitalAsset: "Geely website",
          advertiserGroupId,
          advertisingId,
          url,
          campaignId,
          analyticsid:
            //if window is undefined than we are on server
            typeof window !== "undefined" && window?.document?.cookie
              ? parseCookie(window.document.cookie)?._ga?.split("GA1.1.")?.[1]
              : "",
          gclid,
          campaign,

          // leadLegal:contactData.leadLegalCheckBox?values.leadLegalCheckBox:null
        }).then(extractData)
        if (body) {
          trackCallMeBack()
          trackLeadSuccess(contactData?.contactType, agency.name)
        }

        // if error server return null, then return false

        onSubmit(
          !!(body && (body.leadNumber || body.leadNumber === 0)) || false
        )
      }}
    >
      {({ isSubmitting }) => {
        return (
          <>
            <UseFocusFirstInvalidField
              fieldNameInputRefMap={fieldNameInputRefMap}
            />
            <StyledForm noValidate>
              <Grid container spacing={isDesktop ? 2 : 0}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="firstName"
                    component={StyledTextField}
                    label="שם פרטי"
                    autoComplete="given-name"
                    id="firstName"
                    required
                    helperText=" " // prevent a "jump" when an error appears
                    inputRef={firstNameRef}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="lastName"
                    component={StyledTextField}
                    label="שם משפחה"
                    autoComplete="family-name"
                    id="lastName"
                    required
                    helperText=" " // prevent a "jump" when an error appears
                    inputRef={lastNameRef}
                  />
                </Grid>
              </Grid>
              <Field
                name="phone"
                id="phone"
                component={StyledTextField}
                type="tel"
                label="מספר טלפון"
                autoComplete="tel-national"
                required
                helperText=" " // prevent a "jump" when an error appears
                inputRef={phoneRef}
              />
              <Field
                name="email"
                id="email"
                component={StyledTextField}
                type="email"
                label="דואר אלקטרוני"
                autoComplete="email"
                required
                helperText=" " // prevent a "jump" when an error appears
                inputRef={emailRef}
              />
              <ShowRoomSelectContextUs
                inputRef={showroomsRef}
                data={showRooms}
              />
              {(contactData?.contactType === ContactType.finance ||
                contactData?.contactType === ContactType.leasing ||
                typeof contactData?.contactType === "string") && (
                <StyledField
                  inputProps={{ maxLength: 500 }}
                  name="remarks"
                  id="remarks"
                  component={StyledTextField}
                  multiline
                  rows={3}
                  type="textarea"
                  label="הערות"
                  helperText=" " // prevent a "jump" when an error appears
                  inputRef={remarksRef}
                />
              )}

              {contactData?.leadLegalCheckBox && (
                <FormikCheckboxField
                  name="leadLegalCheckBox"
                  label={contactData?.leadLegalText}
                  inputRef={leadLegalCheckBoxRef}
                  required={contactData?.leadLegalIsRequired}
                />
              )}
              <FormikCheckboxField
                name="agreeMarketingOffers"
                label="מאשר/ת קבלת מידע על מבצעים ופעילויות, וכן יצירת קשר בפנייה שיווקית, על ידי חברת גיאו מוביליטי. אין בהסכמה לפנייה השיווקית כאמור כדי להעיד על הסכמה לביצוע עסקה כלשהי."
                inputRef={agreeMarketingOffersRef}
              />

              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={RECAPTCHA_KEY}
                size="invisible"
              />

              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                fullWidth
              >
                {contactData?.contactType === ContactType.onlinePurchase
                  ? "להשארת פרטים"
                  : "שליחה"}
              </StyledButton>
              <StyledLegal>
                ידוע לי כי הפרטים שמסרתי לעיל יכללו במאגר המידע של גיאו מוביליטי
                בהתאם
                <StyledLegalLink href="/PrivacyPolicy.pdf" openInNewTab={true}>
                  {"  "}
                  למדיניות הפרטיות{"  "}
                </StyledLegalLink>
                של החברה
              </StyledLegal>
            </StyledForm>
          </>
        )
      }}
    </Formik>
  )
}

export default ContactForm
