const PURCHASE_MODEL_ID = process.env.PURCHASE_MODEL_ID

const GEOMETRY_C_ID = "26"
const GEOMETRY_C_NEW_ID = "27"

const GEOMETRY_C = "geometry c"
const GEOMETRY_C_NEW = "geometry c"

const GEOMETRY_C_HEBREW = "ג'יאומטרי סי"
const GEOMETRY_C_NEW_HEBREW = "ג'יאומטרי סי"

const getModelName = (modelId: string, lang: "ENG" | "HEB") => {
  switch (modelId) {
    case GEOMETRY_C_ID:
      return lang === "ENG" ? GEOMETRY_C : GEOMETRY_C_HEBREW
    case GEOMETRY_C_NEW_ID:
      return lang === "ENG" ? GEOMETRY_C_NEW : GEOMETRY_C_NEW_HEBREW
    default:
      return GEOMETRY_C
  }
}

export const getModelNameEnglish = () => {
  return getModelName(PURCHASE_MODEL_ID, "ENG")
}

export const getModelNameHebrew = () => {
  return getModelName(PURCHASE_MODEL_ID, "HEB")
}
