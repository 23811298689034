import { AsyncThunkAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  BatteryPropertiesUnionData,
  cancelGetPricesRequest,
  CarModel,
  extractData,
  getEcommModelsId,
  getPrices,
  getPricesLicensingFee,
  getPricesWithCancellation,
  getSettingsAll,
  LicensingFeeResponse,
  PriceItem,
} from "api"
import { setSelectedAccessories } from "features/accessoriesAndServices/accessoriesAndServicesSlice"
import { setSelectedColor } from "features/color/colorSlice"
import {
  fetchBatteries,
  setSelectedBattery,
  setSelectedFinishLevel,
} from "features/detailing/detailingSlice"
import { setSelectedUpholstery } from "features/upholstery/upholsterySlice"
import { createAsyncAppThunk, ThunkApiConfig } from "store"
import { buildQueryString, getQueryParams } from "./queryParams"

const name = "wizard"

const ORDER_SUMMERY_PDF = "OrderSummaryPDF"

export enum ActiveStep {
  "detailing",
  "color",
  "accessories",
  "warranty",
  "summery",
}

export type WizardState = {
  model: CarModel | null
  unionModels: BatteryPropertiesUnionData | null
  price: PriceItem | null
  licensingFee: LicensingFeeResponse | null
  initialized: boolean
  catalogPdfUrl: string
  basicPrice: PriceItem | null
  activeStep: string
}

const initialState: WizardState = {
  model: null,
  unionModels: null,
  price: null,
  licensingFee: null,
  initialized: false,
  catalogPdfUrl: "",
  basicPrice: null,
  activeStep: ActiveStep[0],
}

export const fetchModel = createAsyncAppThunk(
  `${name}/fetchModel`,
  async (modelId: string, { dispatch }) => {
    const body = await getEcommModelsId(modelId).then(extractData)
    await dispatch(fetchBatteries(modelId))

    return body
  }
)

export const updatePriceAndURL = createAsyncAppThunk(
  `${name}/updatePriceAndURL`,
  async (_, { getState }) => {
    const {
      detailing: { selectedBatteryCode: detailing },
      detailing: { selectedFinishLevelCode: finishingLevelCode },
      color: { selectedColorCode: color },
      upholstery: { selectedUpholsteryCode: upholstery },
      accessories: { selectedAccessoriesCodes: accessories },
    } = getState()
    // Cancel the previous request
    cancelGetPricesRequest?.()
    const body = await getPricesWithCancellation(
      detailing,
      color,
      upholstery,
      finishingLevelCode,
      accessories
    ).then(extractData)

    if (typeof window !== "undefined") {
      // Build URL params
      const values = {
        battery: detailing,
        color,
        upholstery,
        accessories,
        finishingLevel: finishingLevelCode,
      }
      const params = buildQueryString(values)
      // Replace URL and preserve hash
      const url = `?${params}${location.hash}`
      history.replaceState(null, document.title, url)
    }

    return body
  }
)

export const updateLicensingFee = createAsyncAppThunk(
  `${name}/updateLicensingFee`,
  async (code: string) => {
    const body = await getPricesLicensingFee(code).then(extractData)
    return body
  }
)

export const updateBasicPrice = createAsyncAppThunk(
  `${name}/updateBasicPrice`,
  async (_, { getState }) => {
    const {
      detailing: { selectedBatteryCode: detailing },
      detailing: { selectedFinishLevelCode: finishingLevelCode },
    } = getState()
    const body = await getPrices(
      detailing,
      null,
      null,
      finishingLevelCode,
      null
    ).then(extractData)
    return body
  }
)

export const initSelectedComponentsFromParams = createAsyncAppThunk(
  `${name}/initSelectedComponentsFromParams`,
  async (modelId: string, { getState, dispatch }) => {
    const params = getQueryParams()

    const firstBatteryCode = getState().detailing.batteries[0].details.code

    // Prepare the `setSelected*` actions for each of the provided params
    const actions: Array<AsyncThunkAction<any, any, ThunkApiConfig> | null> = [
      // Re-fetch the detailings in case there's a discount for authenticated users
      // fetchBatteries(modelId),

      setSelectedBattery(params.battery || firstBatteryCode),
      // checking if a query param is available inside query string
      params.finishingLevel
        ? setSelectedFinishLevel(params.finishingLevel)
        : null,
      params.color ? setSelectedColor(params.color) : null,
      params.upholstery ? setSelectedUpholstery(params.upholstery) : null,
      params.accessories && params.accessories.length > 0
        ? setSelectedAccessories(params.accessories)
        : null,
    ]
    // if particular query is available then invoke dispatch on particular query
    await Promise.allSettled(
      actions.filter((action) => action !== null).map(dispatch)
    )
  }
)

export const fetchCatalogPdf = createAsyncAppThunk(
  `${name}/fetchCatalogPdf`,
  async () => {
    const body = await getSettingsAll().then(extractData)
    const pdfItem = body?.find((item) => item.key === ORDER_SUMMERY_PDF)
    return pdfItem?.value ?? ""
  }
)

const wizard = createSlice({
  name,
  initialState,
  reducers: {
    setPrice(state, { payload }: PayloadAction<WizardState["price"]>) {
      state.price = payload
    },
    setActiveStep(state, { payload }: PayloadAction<number>) {
      state.activeStep = ActiveStep[payload] || ActiveStep[0]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchModel.fulfilled, (state, { payload }) => {
        state.model = payload.components.details
        state.unionModels = payload.unionData
      })
      .addCase(updatePriceAndURL.fulfilled, (state, { payload }) => {
        state.price = payload
      })
      .addCase(updateLicensingFee.fulfilled, (state, { payload }) => {
        state.licensingFee = payload
      })
      .addCase(updateBasicPrice.fulfilled, (state, { payload }) => {
        state.basicPrice = payload
      })
      .addCase(initSelectedComponentsFromParams.fulfilled, (state) => {
        state.initialized = true
      })
      .addCase(fetchCatalogPdf.fulfilled, (state, { payload }) => {
        state.catalogPdfUrl = payload
      })
  },
})

export const { setPrice, setActiveStep } = wizard.actions

export default wizard.reducer
