import { extractData, getMenusGetMenuByTypeId, MenusResponse } from "api"
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"

const IS_LANDING_PAGE = process.env.IS_LANDING_PAGE

const DISABLE_PURCHASE = process.env.DISABLE_PURCHASE

export type AllMenus = {
  sideMenuItems: MenusResponse
  headerMenuItems: MenusResponse
  footerMenuItems: MenusResponse
}

export const fetchMenus = async () => {
  const [sideMenuItems, headerMenuItems, footerMenuItems] = await Promise.all([
    getMenusGetMenuByTypeId(1).then(extractData),
    getMenusGetMenuByTypeId(2).then(extractData),
    getMenusGetMenuByTypeId(3).then(extractData),
  ])
  return { sideMenuItems, headerMenuItems, footerMenuItems }
}

export type LayoutContextType = {
  isLandingPage: boolean
  setIsLandingPage: Dispatch<SetStateAction<boolean>>
  focusMain: () => void
  setMainRef: (node: HTMLDivElement) => void
  selectedScrollSection: string
  setSelectedScrollSection: Dispatch<SetStateAction<string>>
  isPurchaseEnabled: boolean
}
const LayoutContext = createContext<LayoutContextType>({
  focusMain: () => {},
  setMainRef: () => {},
  isLandingPage: false,
  setIsLandingPage: () => {},
  selectedScrollSection: "",
  setSelectedScrollSection: () => {},
  isPurchaseEnabled: !DISABLE_PURCHASE || DISABLE_PURCHASE === "false",
})

export const LayoutProvider: React.FC = ({ children }) => {
  const [isLandingPage, setIsLandingPage] = useState(
    !!(IS_LANDING_PAGE && IS_LANDING_PAGE === "true")
  )
  const mainRef = useRef<HTMLDivElement | null>(null)
  const [selectedScrollSection, setSelectedScrollSection] = useState("")

  useEffect(() => {
    if (!isLandingPage) {
      setSelectedScrollSection("")
    }
  }, [isLandingPage])

  return (
    <LayoutContext.Provider
      value={{
        focusMain: () => mainRef.current?.focus(),
        setMainRef: (node) => (mainRef.current = node),
        isLandingPage,
        setIsLandingPage,
        selectedScrollSection,
        setSelectedScrollSection,
        isPurchaseEnabled: !DISABLE_PURCHASE || DISABLE_PURCHASE === "false",
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export const useLayoutContext = () => useContext(LayoutContext)
