import { createGlobalStyle, css } from "styled-components"

const GeelyFontFaces = css`
  @font-face {
    font-family: "Geely";
    src: url("/fonts/Geely-light.ttf");
    src: url("/fonts/Geely-light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
  }
  @font-face {
    font-family: "Geely";
    src: url("/fonts/Geely-normal.ttf");
    src: url("/fonts/Geely-normal.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Geely";
    src: url("/fonts/Geely-bold.ttf");
    src: url("/fonts/Geely-bold.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
  }
  @font-face {
    font-family: "GeelyEnglishOnly";
    src: url("/fonts/Geely-bold.ttf");
    src: url("/fonts/Geely-bold.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    unicode-range: U+0000-017F;
  }
`

const GlobalStyle = createGlobalStyle`
${GeelyFontFaces}

  :root {
    direction: rtl;
    font-family: 'Rubik', sans-serif;  }

  body {
    background-color: ${(p) => p.theme.colors.white};
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  /* glassix styles: */
  #glassix-container #glassix-widget-iframe-container{
    z-index: 1200 !important;
  }
  #glassix-container #greeting-message-container{
    z-index: 1200 !important;
  }
  #glassix-container #glassix-widget-launcher-container {
    z-index: 1200 !important;
    @media only screen and (max-width: 959px) {
        margin-left: -10px;
        margin-bottom: 50px;
    }
  }

  /* theme TouchRipple animation override */
  @keyframes MuiTouchRipple-keyframes-enter {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 0.15;
    }
  }
`

export default GlobalStyle
