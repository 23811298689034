import { ContactType } from "layout/contact/ContactForm"
import { getModelNameEnglish } from "./modelConsts"

export type SelectedComponentsForAnalytics = {
  modelName: string
  modelId: number
  totalPrice: number
  detailingName: string
  colorName: string
  upholsteryName: string
  accessories: Array<{ name: string; id: string; price: number }>
}

const modelName = getModelNameEnglish()

const GTM_ID = process.env.GTM_ID
if (!GTM_ID) {
  throw new Error("Please provide the GTM_ID environment variable.")
}

/* Initialize analytics & load plugins */
let analytics

export const initAnalytics = async () => {
  const Analytics = await import("analytics")
  const googleTagManager = await import("@analytics/google-tag-manager")
  analytics = Analytics.default({
    app: "Geely",
    plugins: [googleTagManager.default({ containerId: GTM_ID })],
  })
}

export const trackPage = () => analytics?.page()

export const trackImpressions = (models: Array<{ name: string; id: number }>) =>
  analytics?.track("eec.impressions", {
    ecommerce: {
      currencyCode: "ILS",
      impressions: models.map(({ name, id }) => ({ name, id })),
    },
  })

export const trackProductClick = (name: string, id: number) =>
  analytics?.track("productClick", {
    ecommerce: {
      click: {
        actionField: { list: "Homepage" },
        products: [{ name, id }],
      },
    },
  })

export const trackDetail = (name: string, id: number) => {
  analytics?.track("eec.detail", {
    ecommerce: {
      detail: { products: [{ name, id }] },
    },
  })
}

const buildAnalyticsProducts = ({
  modelName,
  modelId,
  totalPrice,
  detailingName,
  colorName,
  upholsteryName,
  accessories,
}: SelectedComponentsForAnalytics) => [
  {
    name: modelName,
    id: modelId,
    price: totalPrice,
    variant: `${colorName} + ${upholsteryName}`,
    quantity: "1",
    dimension1: detailingName,
  },
  ...accessories,
]

export const trackAddToCart = (components: SelectedComponentsForAnalytics) =>
  analytics?.track("addToCart", {
    ecommerce: {
      currencyCode: "ILS",
      add: {
        products: buildAnalyticsProducts(components),
      },
    },
  })

export const trackCheckoutStepWithComponents = (
  components: SelectedComponentsForAnalytics
) =>
  analytics?.track("checkoutStep", {
    ecommerce: {
      checkout: {
        actionField: { step: "1" },
        products: buildAnalyticsProducts(components),
      },
    },
  })

export const trackCheckoutStep = (step: "2" | "3" | "4" | "5") =>
  analytics?.track("checkoutStep", {
    ecommerce: {
      checkout: {
        actionField: { step },
      },
    },
  })
export const trackTransaction = (
  components: SelectedComponentsForAnalytics,
  orderId: number
) =>
  analytics?.track("transaction", {
    ecommerce: {
      purchase: {
        actionField: {
          id: orderId,
          revenue: components.totalPrice,
        },
        products: buildAnalyticsProducts(components),
      },
    },
  })

export const trackVehiclePageView = (data: { tabName: string }) =>
  analytics?.track("vehicle_page_view", {
    model: modelName,
    tab: data.tabName,
  })

export const trackMenuClick = (data: {
  tabName?: string
  menuLocation: "top" | "side" | "footer"
  LinkText: string
}) => {
  if (data.tabName) {
    analytics?.track("menu_click", {
      location: data.menuLocation,
      text: data.LinkText,
      tab: data.tabName,
    })
  } else {
    analytics?.track("menu_click", {
      location: data.menuLocation,
      text: data.LinkText,
    })
  }
}

export const trackPurchaseClick = (data: { tabName: string }) =>
  analytics?.track("purchase_click", {
    text: "רכישת אונליין",
    tab: data.tabName,
  })

export const trackIconButtonClick = (data: {
  IconName: "telephone" | "whatsapp"
  tabName: string
}) =>
  analytics?.track(`${data.IconName}_click`, {
    contact: data.IconName,
    tab: data.tabName,
  })

export const trackContentUsClick = (data: {
  location: "top" | "side" | "bottom" | "body"
}) =>
  analytics?.track("contact_form", {
    location: data.location,
  })

export const trackSendPhoneCode = (data: {
  model: string
  agency: number
  step: string
}) => {
  analytics?.track("code_by_sms", {
    model: data.model,
    agency: data.agency,
    step: data.step,
  })
}

// Purchasing process
export const trackFunnelClick = (data: {
  stepName: string
  buttonText: string
  interiorColor?: string
  exteriorColor?: string
}) =>
  analytics?.track("funnel_click", {
    step: data.stepName,
    model: modelName,
    // TODO (not working yet!)
    finance: "",
    text: data.buttonText,
    "exterior color": data.exteriorColor,
    "interior color": data.interiorColor,
  })

export const trackColorChoosing = (data: {
  stepName: string
  interiorColor?: string
  exteriorColor?: string
}) =>
  analytics?.track("color_choosing", {
    step: data.stepName,
    model: modelName,
    "exterior color": data.exteriorColor,
    "interior color": data.interiorColor,
  })

// export const trackFunnelStepView = (data: { stepName: string }) =>
//   analytics?.track("funnel_step_view", {
//     step: data.stepName,
//     model: modelName,
//     // TODO (not working yet!)
//     finance: "",
//   })

export const trackPersonalizePaymentClick = (data: { stepName: string }) =>
  analytics?.track("personalize_payment_click", {
    step: data.stepName,
    model: modelName,
    // // TODO (not working yet!)
    finance: "",
  })

// step 1
// 15.	‘step’: '{השלב בפאנל בו נמצא המשתמש}'
// 16.	‘model’: '{שם הדגם של הרכב}'
// 17.	‘finance’: '{אפשרות המימון שנבחרה}'
// 18.	‘event’:’technical_specifications_click’
// 19.	‘text’: '{טקסט הכפתור שנלחץ}'
// 20.	});

export const trackTechnicalSpecificationsClick = (data: {
  stepName: string
  buttonText: string
}) =>
  analytics?.track("technical_specifications_click", {
    step: data.stepName,
    model: modelName,
    // TODO (not working yet!)
    finance: "",
    text: data.buttonText,
  })

// export const trackTechnicalSpecificationsView = (data: { stepName: string }) =>
//   analytics?.track("technical_specifications_view", {
//     step: data.stepName,
//     model: modelName,
//   })

export const trackTechnicalSpecificationExpand = (data: {
  stepName: string
  buttonText: string
}) =>
  analytics?.track("technical_specification_expand", {
    step: data.stepName,
    model: modelName,
    text: data.buttonText,
  })

export const trackPersonalDetailsClick = (data: { stepName: string }) =>
  analytics?.track("personal_details_click", {
    step: data.stepName,
    model: modelName,
    // TODO (not working yet!)
    finance: "",
  })

export const trackVehicleAccessoryClick = (data: {
  stepName: string
  buttonText: string
}) =>
  analytics?.track("vehicle_accessory_click", {
    step: data.stepName,
    model: modelName,
    // TODO (not working yet!)
    finance: "",
    text: data.buttonText,
  })

export const trackVehicleWarrantyClick = (data: {
  stepName: string
  buttonText: string
}) =>
  analytics?.track("vehicle_warranty_click", {
    step: data.stepName,
    model: modelName,
    // TODO (not working yet!)
    finance: "",
    text: data.buttonText,
  })

export const trackTechnicalSpecificationsPdfClick = (data: {
  stepName: string
}) =>
  analytics?.track("technical_specifications_pdf_click", {
    step: data.stepName,
    model: modelName,
    // TODO (not working yet!)
    finance: "",
  })

export const trackStepModelFianance = (data: {
  eventName: string
  stepName: string
}) =>
  analytics?.track(data.eventName, {
    step: data.stepName,
    model: modelName,
    // TODO (not working yet!)
    finance: "",
  })

export const trackSuccessPayment = () => {
  analytics?.track("payment_success", {
    model: modelName,
    // TODO (not working yet!)
    finance: "",
  })
}

export const trackLoggedIn = () => analytics?.track("logged-in")

export const trackCallMeBack = () => analytics?.track("callmeBack")

export const trackFaq = (questionText) =>
  analytics?.track("question_click", { text: questionText })

export const trackOpenPopUpCalc = (type, analyticsObj) =>
  analytics?.track(
    `${type === ContactType.finance ? `finance` : `leasing`}_click`,
    analyticsObj
  )
export const trackLeadSuccess = (type: string, agency: string) => {
  let label = ""
  if (!type) {
    label = "contact"
  } else {
    label = type === ContactType.finance ? `finance` : `leasing`
  }

  analytics?.track(`${label}_lead_success`, {
    agency,
  })
}
export const trackFinanceOption = (data: { stepName: string }) =>
  analytics?.track("finance_options_funnel_click", {
    step: data.stepName,
    model: modelName,
  })
export const trackPersonalDetailesPaymentView = () =>
  analytics?.track("personal_detailes_payment_view", {
    step: "summery",
    model: modelName,
  })
export const trackCreditCardDetailes = () =>
  analytics?.track("credit_card_detailes", {
    step: "summery",
    model: modelName,
  })

export const trackCoordinate = (text: string) =>
  analytics?.track("test_drive", {
    step: "click to funnel",
    text,
  })
