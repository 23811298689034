import {
  Collapse,
  Divider,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import { MenusItems } from "api"
import { trackMenuClick } from "common/analytics"
import NextLink from "common/NextLink"
import Image from "next/image"
import { useRouter } from "next/router"
import React, { useState } from "react"
import styled from "styled-components"
import { useLayoutContext } from "./LayoutContext"

const Styled = {
  ListItem: styled(ListItem).attrs({
    button: true,
  })<{ target?: string; $isBlue?: boolean }>`
    margin: 0;
    padding-right: 50px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;

    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    .MuiListItemText-primary {
      color: ${(p) => (p.$isBlue ? p.theme.colors.niceBlue : "")};
      font-weight: ${(p) => (p.$isBlue ? "bold" : "normal")};
    }
  `,
  ListSubItemTitle: styled(Typography).attrs({
    variant: "h4",
    component: "li",
  })<{ $isBlue?: boolean }>`
    margin: 0;
    padding-right: 50px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;

    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    .MuiListItemText-primary {
      color: ${(p) => (p.$isBlue ? p.theme.colors.niceBlue : "")};
      font-weight: ${(p) => (p.$isBlue ? "bold" : "normal")};
    }
  `,

  SubMenuItems: styled.div``,
  ListSecondSubItem: styled(ListItem).attrs({
    button: true,
    component: "a",
  })<{ target?: string }>`
    padding-right: 50px;
    .span {
      text-decoration: none !important;
    }
  `,
  Divider: styled(Divider)`
    margin: 15px 50px;
    background-color: ${(p) => p.theme.colors.veryLightPink};
  `,
  ListSubItemText: styled(ListItemText)`
    text-decoration: none;
  `,
}

type SubListItemProps = {
  subListItem: MenusItems
  isLast: boolean
}

const SubListItem = ({ subListItem, isLast }: SubListItemProps) => {
  const [collapseSecondSubMenu, setCollapseSecondSubMenu] =
    useState<boolean>(false)
  const { pathname } = useRouter()
  const { selectedScrollSection } = useLayoutContext()

  const sendEventLink = (pageName: string) => {
    // only if we are on the landings page!
    if (pathname === "/") {
      trackMenuClick({
        menuLocation: "side",
        tabName: selectedScrollSection,
        LinkText: pageName,
      })
    }
  }
  return (
    <>
      {subListItem.subItems?.length > 0 ? (
        <Styled.ListItem
          $isBlue={subListItem.subItems?.length > 0 && collapseSecondSubMenu}
          onClick={(e) => {
            e.stopPropagation()
            setCollapseSecondSubMenu((prevState) => !prevState)
          }}
          key={subListItem.id}
        >
          <Styled.ListSubItemText primary={subListItem.title} />
          {subListItem.subItems?.length > 0 && !collapseSecondSubMenu && (
            <Image
              src="/noun_black_Arrow.webp"
              width="8"
              height="5"
              alt="arrow-icon"
              objectFit="contain"
            />
          )}
          {subListItem.subItems?.length > 0 && collapseSecondSubMenu && (
            <Image
              src="/noun_blue_Arrow.webp"
              width="8"
              height="5"
              alt="arrow-icon"
              objectFit="contain"
            />
          )}
        </Styled.ListItem>
      ) : (
        <NextLink href={subListItem.file?.imageUrl || subListItem.externalUrl}>
          <Styled.ListSubItemTitle
            onClick={(e) => {
              e.stopPropagation()
              sendEventLink(subListItem.title)
            }}
          >
            <Styled.ListSubItemText primary={subListItem.title} />
          </Styled.ListSubItemTitle>
        </NextLink>
      )}
      {subListItem.subItems?.length > 0 && (
        <Collapse in={collapseSecondSubMenu} timeout="auto">
          <Styled.SubMenuItems>
            {subListItem.subItems.map((subSecondListItem) => (
              <NextLink
                href={
                  subSecondListItem.file?.imageUrl ||
                  subSecondListItem.externalUrl
                }
                key={subSecondListItem.id}
                openInNewTab={subSecondListItem.isNewTab}
                passHref
              >
                <Styled.ListSecondSubItem
                  aria-label={`קישור ל${subSecondListItem.title}`}
                  onClick={() => sendEventLink(subSecondListItem.title)}
                >
                  <Styled.ListSubItemText primary={subSecondListItem.title} />
                </Styled.ListSecondSubItem>
              </NextLink>
            ))}
          </Styled.SubMenuItems>
          {!isLast && <Styled.Divider />}
        </Collapse>
      )}
    </>
  )
}

export default SubListItem
