import {
  ClickAwayListener,
  Grow,
  ListItem,
  ListItemText,
  MenuList,
  Paper,
} from "@material-ui/core"
import { MenusItems } from "api"
import NextLink from "common/NextLink"
import { trackMenuClick } from "common/analytics"
import dynamic from "next/dynamic"
import Image from "next/image"
import { useRouter } from "next/router"
import React from "react"
import styled from "styled-components"
import HeaderSubListItem from "./HeaderSubListItem"
import { useLayoutContext } from "./LayoutContext"

const Popper = dynamic(() => import("@material-ui/core/Popper"), { ssr: false })

const StyledListContainer = styled.li`
  padding: 0;
  margin: 0;
`

const StyledListItem = styled(ListItem).attrs({
  button: true,
})<{ target?: string; component: string; ref?: any }>`
  margin: 0;
  padding: 0 5px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: none;
  border: none;
  > span {
    margin-right: 12px !important;
  }
  .span {
    text-decoration: none !important;
  }
`

const StyledListItemText = styled(ListItemText)<{ $isBlue?: boolean }>`
  text-decoration: none;
  .MuiListItemText-primary {
    font-weight: ${(p) => (p.$isBlue ? "500" : "normal")};
    color: ${(p) => (p.$isBlue ? p.theme.colors.niceBlue : "")};
  }
`
const StyledPaper = styled(Paper)`
  min-width: 218px;
  padding: 7px 24px 12px;
  top: 60px !important;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`

type HeaderListItemProps = {
  menuItem: MenusItems
}

const HeaderListItem = ({ menuItem }: HeaderListItemProps) => {
  const { pathname } = useRouter()
  const { selectedScrollSection } = useLayoutContext()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  function handleListKeyDownShiftTab(event: React.KeyboardEvent) {
    if (event.code === "Tab") {
      if (event.shiftKey) {
        //shift+tab pressed
        setOpen(false)
      }
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  const sendEventLink = (pageName: string) => {
    // only if we are on the landings page!
    if (pathname === "/") {
      trackMenuClick({
        menuLocation: "top",
        tabName: selectedScrollSection,
        LinkText: pageName,
      })
    }
  }
  return (
    <StyledListContainer>
      {menuItem.subItems?.length > 0 ? (
        <StyledListItem
          aria-expanded={open}
          component="button"
          aria-label={`פתיחת תפריט ${menuItem.title}`}
          aria-controls={open ? `menu_${menuItem.id}` : undefined}
          aria-haspopup="true"
          onKeyDown={handleListKeyDownShiftTab}
          ref={anchorRef}
          id={menuItem.title}
          onClick={(e) => {
            e.stopPropagation()
            handleToggle()
          }}
          key={menuItem.id}
        >
          <StyledListItemText $isBlue={open} primary={menuItem.title} />

          {!open && (
            <Image
              src="/noun_black_Arrow.webp"
              width="8"
              height="5"
              alt="arrow-icon"
              objectFit="contain"
            />
          )}
          {open && (
            <Image
              src="/noun_blue_Arrow.webp"
              width="8"
              height="5"
              alt="arrow-icon"
              objectFit="contain"
            />
          )}
        </StyledListItem>
      ) : (
        <NextLink
          href={menuItem.file?.imageUrl || menuItem.externalUrl}
          passHref
        >
          <StyledListItem
            component="a"
            aria-label={`קישור ל${menuItem.title}`}
            onClick={() => {
              sendEventLink(menuItem.title)
            }}
          >
            <StyledListItemText primary={menuItem.title} />
          </StyledListItem>
        </NextLink>
      )}

      {menuItem.subItems?.length && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 1,
            position: "fixed",
            top: 0,
            left: 0,
            visibility: "hidden",
          }}
          keepMounted
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
              onExited={(node) => {
                node.parentElement.style.position = "fixed"
                node.parentElement.style.top = "0"
                node.parentElement.style.left = "0"
                node.parentElement.style.visibility = "hidden"
              }}
              onEnter={(node) => {
                node.parentElement.style.visibility = "visible"
              }}
            >
              <StyledPaper>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  onClickAway={handleClose}
                >
                  <MenuList
                    id={`menu_${menuItem.id}`}
                    onKeyDown={handleListKeyDown}
                    autoFocusItem={open}
                    aria-label="נווט בין האלמנטים עם חץ למטה/למעלה"
                  >
                    {menuItem.subItems.map((subItem, index) => {
                      return (
                        <HeaderSubListItem
                          key={subItem.id}
                          subListItem={subItem}
                          isLast={menuItem.subItems.length === index + 1}
                          closeMenu={() => setOpen(false)}
                        />
                      )
                    })}
                  </MenuList>
                </ClickAwayListener>
              </StyledPaper>
            </Grow>
          )}
        </Popper>
      )}
    </StyledListContainer>
  )
}

export default HeaderListItem
