import { createSlice } from "@reduxjs/toolkit"
import {
  CarExtraItem,
  CarExtraItemDtoUnionComponent,
  extractData,
  getExtras,
} from "api"
import { updatePriceAndURL } from "features/wizard/wizardSlice"
import { createAsyncAppThunk } from "store"
import { getExtrasStockBypass } from "../../api/generated/GeelyApi"

const name = "accessories"
const DISABLE_PURCHASE = process.env.DISABLE_PURCHASE === "true"

export type AccessoriesState = {
  selectedAccessoriesCodes: CarExtraItem["code"][]
  extras: CarExtraItemDtoUnionComponent[]
  accessoryType: number
  warrantyType: number
  serviceType: number
}

const initialState: AccessoriesState = {
  selectedAccessoriesCodes: [],
  extras: [],
  accessoryType: 1,
  warrantyType: 2,
  serviceType: 3,
}

export const fetchExtras = createAsyncAppThunk(
  `${name}/fetchExtras`,
  async (_, { dispatch, getState }) => {
    const { selectedBatteryCode: batteryCode, selectedFinishLevelCode } =
      getState().detailing
    let extras
    if (DISABLE_PURCHASE) {
      extras = await getExtrasStockBypass(
        batteryCode,
        selectedFinishLevelCode
      ).then(extractData)
    } else {
      extras = await getExtras(batteryCode, selectedFinishLevelCode).then(
        extractData
      )
    }

    const { selectedAccessoriesCodes } = getState().accessories

    // Re-select all the currently selected accessories which also exist in the
    // new set of accessories & all the free accessories
    const accessoriesToSelect = extras
      .filter(
        (a) =>
          selectedAccessoriesCodes.includes(a.details.code) ||
          a.details.isDefaultCheck
      )
      .map((accessory) => accessory.details.code)
    dispatch(setSelectedAccessories(accessoriesToSelect))

    return extras
  }
)

export const setSelectedAccessories = createAsyncAppThunk(
  `${name}/setSelectedAccessories`,
  (
    _accessoriesCodes: AccessoriesState["selectedAccessoriesCodes"],
    { dispatch }
  ) => {
    dispatch(updatePriceAndURL())
  }
)

const accessories = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExtras.fulfilled, (state, { payload }) => {
        state.extras = payload
      })
      .addCase(setSelectedAccessories.pending, (state, { meta }) => {
        state.selectedAccessoriesCodes = meta.arg
      })
  },
})

export default accessories.reducer
