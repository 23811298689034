import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { updatePriceAndURL } from "features/wizard/wizardSlice"

const name = "warranty"

export type WarrantyState = {
  selectedWarrantiesCodes: Array<string>
}

const initialState: WarrantyState = {
  selectedWarrantiesCodes: [],
}

export const setWarranties = createAsyncThunk(
  `${name}/setWarranties`,
  async (warrantyCodes: string[], { dispatch }) => {
    dispatch(updatePriceAndURL())

    return warrantyCodes
  }
)

const warranty = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setWarranties.fulfilled, (state, { payload }) => {
      state.selectedWarrantiesCodes = payload
    })
  },
})

export default warranty.reducer
