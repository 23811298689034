import qs from "qs"

type UtmParams = {
  utm_medium: string
  utm_source: string
  camp_id: string
  adgroup_id: string
  ad_id: string
  campaign: string
  gclid: string
}

type UtmProps = {
  medium: string
  source: string
  campaignId: string
  advertiserGroupId: string
  advertisingId: string
  url: string
  campaign: string
  gclid: string
}

const getUtmParams = (): UtmProps => {
  const params = qs.parse(location.search.toLowerCase(), {
    ignoreQueryPrefix: true,
  }) as Partial<UtmParams>
  return {
    medium: params?.utm_medium,
    source: params.utm_source,
    campaignId: params.camp_id,
    advertiserGroupId: params.adgroup_id,
    advertisingId: params.ad_id,
    url: location.search,
    campaign: params.campaign,
    gclid: params.gclid,
  }
}

export default getUtmParams
