import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface PopupState {
  isCarInfo: boolean
  isPurchaseForm: boolean
  isOTP: boolean
  isSuccessPurchase: boolean
  isPaymentForm: boolean
  isQrCode: boolean
}

const name = "popup"

const initialState: PopupState = {
  isCarInfo: false,
  isPurchaseForm: false,
  isOTP: false,
  isSuccessPurchase: false,
  isPaymentForm: false,
  isQrCode: false,
}

const popup = createSlice({
  name,
  initialState,
  reducers: {
    setSuccessPurchase(
      state,
      { payload }: PayloadAction<PopupState["isSuccessPurchase"]>
    ) {
      state.isSuccessPurchase = payload
    },

    setIsOTP(state, { payload }: PayloadAction<PopupState["isOTP"]>) {
      state.isOTP = payload
    },

    setIsPurchaseForm(
      state,
      { payload }: PayloadAction<PopupState["isPurchaseForm"]>
    ) {
      state.isPurchaseForm = payload
    },

    setQrCode(state, { payload }: PayloadAction<PopupState["isQrCode"]>) {
      state.isQrCode = payload
    },

    setIsCarInfo(state, { payload }: PayloadAction<PopupState["isCarInfo"]>) {
      state.isCarInfo = payload
    },
    setPaymentForm(
      state,
      { payload }: PayloadAction<PopupState["isPaymentForm"]>
    ) {
      state.isPaymentForm = payload
    },
  },
})

export const {
  setIsCarInfo,
  setIsPurchaseForm,
  setIsOTP,
  setSuccessPurchase,
  setPaymentForm,
  setQrCode,
} = popup.actions

export default popup.reducer
