import { heIL } from "@material-ui/core/locale"
import { createTheme, ThemeOptions } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import { Shadows } from "@material-ui/core/styles/shadows"

export const colors = {
  // bronco colors - to delete
  veryLightGrey: "#f5f5f5",
  midGray: "#414141",
  darkBlue: "#00366e",
  darkOrange: "#c44028",
  darkSlateBlue: "#112b4e",
  bluishGrey: "#8794a1",
  lightBlue: "#2861a4",
  black: "#000000",
  whiteGrey: "#d5d5d5",
  marineBlue: "#013378",

  // new colors
  paleGrey: "#f5f7f8",
  paleGreyThree: "#f0eff4",
  cloudyBlue: "#cfd9e0",
  midnight: "#040e19",
  midnightOpacity: "rgb(4, 14, 25,0.3)",
  redPink: "#ea424a",
  tiffanyBlue: "#62cce4",
  midBlue: "#1c67b2",
  white: "#ffffff",
  coolGrey: "#9aa4ab",
  aquaBlue: "#00d7f0",
  rosePink: "#ef878c",
  coolGreyTwo: "#aba9b2",
  greyishBrown: "#575757",
  steelGrey: "#778086",
  mainPurple: "#A238FF",
  battleshipGrey: "#6c737a",
  battleshipGreyOpacity: "rgba(108, 115, 122, 0.3)",

  // the newest colors related to geely project
  darkNavyBlue: "#001221",
  lighterPurple: "#9153fd",
  silver: "#dce1e3",
  mainBlue: "#0e7eaf",
  textGrey: "#6F7174",
  polar: "#f0f6fc",
  abbey: "#5b5d60",
  footerText: "#5B5D60",
  aliceBlue: "#F0F6FC",
  cornflower: "#6e74fa",
  niceBlue: "#0e7eaf",
  transparent: "transparent",
  veryLightPink: "#cccccc",
  successGreen: "#0a8572",
}

const breakpoints = createBreakpoints({})

const muiThemeOptions: ThemeOptions = {
  direction: "rtl",
  palette: {
    primary: {
      main: colors.midnight,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.white,
      contrastText: colors.lightBlue,
    },
    text: {
      primary: colors.midnight,
      secondary: colors.white,
    },
    error: {
      main: "#b50303",
    },
  },
  shadows: new Array(25).fill("none") as Shadows, // disable all shadows
  shape: { borderRadius: 2 },
  typography: {
    fontFamily: "'Rubik', sans-serif",
    h2: {
      fontSize: "1.125rem",
      fontWeight: 500,
      // letterSpacing: "0.72px",
      margin: "16px 0",
      lineHeight: 1,

      [breakpoints.up("md")]: {
        fontSize: "1.25rem",
      },
    },
    h3: {
      fontSize: "0.875rem",
      fontWeight: 600,
      // letterSpacing: "0.56px",
      marginTop: "24px",
      marginBottom: "20px",
      // borderBottom: `1px solid ${colors.black}`,
      paddingBottom: "8px",

      [breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
    h4: {
      fontSize: "0.875rem",
      fontWeight: 600,
      // letterSpacing: "0.84px",
      marginTop: "20px",
      marginBottom: "8px",
      color: colors.darkSlateBlue,

      [breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },

    body1: {
      lineHeight: 1,
      // letterSpacing: 1,
      color: colors.midnight,
    },
  },
  props: {
    MuiFormControl: {
      fullWidth: true,
    },
    MuiTextField: {
      fullWidth: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiLink: {
      underline: "always",
    },
  },
}

const muiTheme = createTheme(muiThemeOptions, heIL)

muiThemeOptions.overrides = {
  MuiButton: {
    contained: {
      margin: "3px 4px 3px 0",
      fontWeight: "normal",
      width: "155px",
      height: "44px",
      fontSize: "1rem",
      letterSpacing: "0.64px",
      [breakpoints.up("md")]: {
        fontSize: "18px",
        height: "50px",
      },
      "&:hover": {
        backgroundColor: colors.lightBlue,
        color: colors.white,
        outline: `2px solid ${colors.lightBlue}`,
      },
      "@media (hover: none)": {
        "&&:hover": {
          backgroundColor: colors.lightBlue,
          color: colors.white,
          outline: `2px solid ${colors.lightBlue}`,
        },
      },
      "&:focus": {
        backgroundColor: colors.lightBlue,
        color: colors.white,
        outline: `2px solid ${colors.lightBlue}`,
      },
      "&$disabled": {
        background: colors.midGray,
        color: colors.white,
      },
    },
    root: {
      "&:focus": {
        outline: "1px solid currentColor",
      },
      "&:hover": {
        backgroundColor: colors.veryLightGrey,
      },
      "@media (hover: none)": {
        "&&:hover": {
          backgroundColor: colors.veryLightGrey,
        },
      },
    },
  },
  MuiTouchRipple: {
    rippleVisible: {
      opacity: 0.15,
      animation: `$enter 550ms ${muiTheme.transitions.easing.easeInOut}`,
    },
  },
  MuiInputLabel: {
    formControl: {
      position: "static",
    },
    shrink: {
      transform: "none",
    },
  },
  MuiFormLabel: {
    root: {
      color: colors.darkSlateBlue,
      fontWeight: 600,
      letterSpacing: "0.16px",
      "&$error": {
        color: colors.darkSlateBlue,
      },
      "& + .MuiInput-formControl": {
        marginTop: "6px",
      },
    },
    asterisk: {
      color: muiTheme.palette.error.main,
    },
  },
  MuiInput: {
    input: {
      border: `1px solid ${colors.darkSlateBlue}`,
      borderRadius: muiTheme.shape.borderRadius,
      height: "40px",
      boxSizing: "border-box",
      padding: muiTheme.spacing(1),
    },
    root: {
      "&$disabled": {
        color: colors.bluishGrey,
      },
    },
  },

  MuiFormControl: {
    root: {
      marginBottom: muiTheme.spacing(0.5),
    },
  },
  MuiFormControlLabel: {
    label: {
      color: colors.darkSlateBlue,
    },
  },
  MuiFormHelperText: {
    root: {
      marginTop: 0,
    },
  },
  MuiDialog: {
    paper: {
      margin: "12px",
    },
  },
  MuiCheckbox: {
    colorPrimary: {
      color: colors.darkSlateBlue,
      "&$checked": {
        color: colors.darkSlateBlue,
      },
      "&$disabled": {
        color: colors.darkSlateBlue,
      },
    },
  },
  MuiTab: {
    root: {
      fontSize: "14px",
      lineHeight: 1,
      fontWeight: "normal",
    },
    textColorInherit: {
      "&$disabled": {
        opacity: 1,
        fontWeight: "normal",
      },
    },
  },
  MuiCircularProgress: {
    colorPrimary: {
      color: colors.aquaBlue,
    },
    colorSecondary: {
      color: colors.rosePink,
    },
  },
  MuiTabs: {
    indicator: {
      color: colors.midnight,
      backgroundColor: colors.aquaBlue,
    },
  },
  MuiSlider: {
    root: {},
    markLabel: {
      color: colors.coolGreyTwo,
      fontSize: "16px",
    },
    markLabelActive: {
      color: colors.coolGreyTwo,
      fontSize: "16px",
    },
    rail: {
      height: "15px",
      borderRadius: "70px",
      color: colors.paleGreyThree,
      opacity: "1",
    },
    track: {
      height: "15px",
      borderRadius: "70px",
      backgroundImage: `linear-gradient(
        to left,
        ${colors.lighterPurple},
        ${colors.aquaBlue}
      )`,
    },
    mark: {
      display: "none",
    },
    thumb: {
      backgroundColor: colors.white,
      boxShadow: `0 1px 4px 0 ${colors.cloudyBlue}`,
    },
  },
  MuiSwitch: {
    root: {
      width: "50px",
      height: "24px",
      padding: "0px",
      "&$checked": {
        color: "#23bf58 !important",
        transform: "translateX(26px) !important",
      },
    },
    switchBase: {
      color: "#818181",
      padding: "1px",
      "&$checked": {
        "& + $track": {
          backgroundColor: `${colors.paleGrey} !important`,
        },
      },
    },
    thumb: {
      color: "white",
      width: "20px",
      height: "20px",
      margin: "1px",
      boxShadow: `0 1px 4px 0 ${colors.cloudyBlue}`,
    },
    track: {
      borderRadius: "20px",
      backgroundColor: colors.paleGrey,
      opacity: "1 !important",
      "&:after, &:before": {
        fontSize: "10px",
        position: "absolute",
        top: "6px",
      },
      "&:after": {
        content: "'ON'",
        left: "7px",
      },
      "&:before": {
        color: colors.coolGreyTwo,
        content: "'OFF'",
        right: "8px",
      },
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: "rgb(99 103 106 / 40%)",
      backdropFilter: "blur(3px)",
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: colors.mainBlue,
    },
  },
  MuiDrawer: {
    paper: {
      boxShadow: `-10px 0px 35px 1px ${colors.silver}`,
    },
  },
}

const theme = {
  ...createTheme(muiThemeOptions, heIL),
  colors,
}

export default theme

export type Theme = typeof theme
