import {
  Collapse,
  Divider,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import { MenusItems } from "api"
import { trackMenuClick } from "common/analytics"
import NextLink from "common/NextLink"
import Image from "next/image"
import { useRouter } from "next/router"
import { useState } from "react"
import styled from "styled-components"
import { useLayoutContext } from "./LayoutContext"
import SubListItem from "./SubListItem"

const Styled = {
  ListItem: styled(ListItem).attrs({
    button: true,
  })<{ target?: string }>`
    margin: 0;
    padding-right: 50px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 24px;
  `,
  ListItemTitle: styled(Typography).attrs({
    variant: "h3",
    component: "div",
  })`
    margin: 0;
    padding-right: 50px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 24px;
  `,
  ListItemText: styled(ListItemText)<{
    $isBlue?: boolean
  }>`
    text-decoration: none;
    .MuiListItemText-primary {
      font-weight: bold;
      color: ${(p) => (p.$isBlue ? p.theme.colors.niceBlue : "")};
    }
  `,
  SubMenuItems: styled.div``,
  Divider: styled(Divider)`
    margin: 15px 50px;
  `,
}

type CustomedListItemProps = {
  menuItem: MenusItems
  isLast: boolean
}

const CustomedListItem = ({ menuItem, isLast }: CustomedListItemProps) => {
  const [collapseSubMenu, setCollapseSubMenu] = useState<boolean>(false)
  const { pathname } = useRouter()
  const { selectedScrollSection } = useLayoutContext()

  const sendEventLink = (pageName: string) => {
    // only if we are on the landings page!
    if (pathname === "/") {
      trackMenuClick({
        menuLocation: "side",
        tabName: selectedScrollSection,
        LinkText: pageName,
      })
    }
  }
  return (
    <>
      {menuItem.subItems?.length > 0 ? (
        <Styled.ListItem
          aria-expanded={collapseSubMenu}
          onClick={(e) => {
            e.stopPropagation()
            setCollapseSubMenu((prevState) => !prevState)
          }}
          key={menuItem.id}
        >
          <Styled.ListItemText
            $isBlue={collapseSubMenu}
            primary={menuItem.title}
          />

          {!collapseSubMenu && (
            <Image
              src="/noun_black_Arrow.webp"
              width="8"
              height="5"
              alt="arrow-icon"
              objectFit="contain"
            />
          )}
          {collapseSubMenu && (
            <Image
              src="/noun_blue_Arrow.webp"
              width="8"
              height="5"
              alt="arrow-icon"
              objectFit="contain"
            />
          )}
        </Styled.ListItem>
      ) : (
        <NextLink href={menuItem.file?.imageUrl || menuItem.externalUrl}>
          <Styled.ListItemTitle
            onClick={(e) => {
              e.stopPropagation()
              sendEventLink(menuItem.title)
            }}
          >
            <Styled.ListItemText primary={menuItem.title} />
          </Styled.ListItemTitle>
        </NextLink>
      )}

      {menuItem.subItems?.length > 0 && (
        <Collapse in={collapseSubMenu} timeout="auto">
          <Styled.SubMenuItems>
            {menuItem.subItems.map((subItem, index) => {
              return (
                <SubListItem
                  key={subItem.id}
                  subListItem={subItem}
                  isLast={menuItem.subItems.length === index + 1}
                />
              )
            })}
          </Styled.SubMenuItems>
          {!isLast && <Styled.Divider />}
        </Collapse>
      )}
    </>
  )
}

export default CustomedListItem
