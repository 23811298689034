import {
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
} from "@material-ui/core"
import { ShowRooms } from "api"
import { Field, useFormikContext } from "formik"
import { Select } from "formik-material-ui"
import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  padding-bottom: ${(p) => p.theme.spacing(2)}px;
  margin-bottom: ${(p) => p.theme.spacing(0.5)}px;
`
const StyledFormControl = styled(FormControl)`
  margin-bottom: 0;
`

const StyledError = styled(FormHelperText).attrs({ error: true })`
  position: absolute;
`
type ShowRoomSelectProps = {
  inputRef?: React.RefObject<HTMLSelectElement>
  data: ShowRooms[] | null
}
const ShowRoomSelectContextUs = ({ inputRef, data }: ShowRoomSelectProps) => {
  const beforeSort = [...data]

  const showRooms = beforeSort.sort((a, b) =>
    a.id === 4 ? -1 : b.id === 4 ? 1 : 0
  )
  const { errors, touched } = useFormikContext<{ agency: string }>()
  return (
    <StyledContainer>
      <StyledFormControl>
        <InputLabel htmlFor="agency" required>
          אולם תצוגה
          <Typography variant="srOnly">
            {showRooms.filter((showRoom) => showRoom.showRoomType !== 1).length}{" "}
            פריטים ברשימה
          </Typography>
        </InputLabel>

        <Field
          component={Select}
          name="agency"
          id="agency"
          native
          required
          inputRef={inputRef}
          aria-describedby="agency-helper-text"
        >
          <option value={""} disabled>
            בחר/י
          </option>
          {showRooms
            .filter((showRoom) => showRoom.showRoomType !== 1)
            .map((showRoom) => (
              <option key={showRoom.id} value={showRoom.id}>
                {showRoom.name}
              </option>
            ))}
        </Field>
      </StyledFormControl>

      {errors.agency && touched.agency && (
        <StyledError id="agency-helper-text">{errors.agency}</StyledError>
      )}
    </StyledContainer>
  )
}

export default ShowRoomSelectContextUs
