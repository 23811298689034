import { AppBar, Container, Toolbar, Typography } from "@material-ui/core"
import { MenusResponse } from "api"
import NextLink from "common/NextLink"
import { useTypedSelector } from "store"
import styled from "styled-components"

const StyledAppBar = styled(AppBar)<{ $isPurchased: boolean }>`
  z-index: 90;
  position: relative;
  top: auto;
  background-color: ${(p) => p.theme.colors.paleGreyThree};
  min-height: 85px;
  margin-bottom: ${(p) => (p.$isPurchased ? "-85px" : "0")};
  ${(p) => p.theme.breakpoints.down("sm")} {
    z-index: 0;
    height: ${(p) => (p.$isPurchased ? "320px" : "350px")};
    margin-bottom: 0;
  }
  @media print {
    display: none;
  }
`
const StyledToolbar = styled(Toolbar)``

const StyledContainer = styled(Container)`
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${(p) => p.theme.breakpoints.up("md")} {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    min-height: 85px;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledCategories = styled.ul`
  ${(p) => p.theme.breakpoints.up("md")} {
    display: flex;
    flex-wrap: wrap;
  }
  a {
    ${(p) => p.theme.breakpoints.down("sm")} {
      width: 100%;
      display: inline-block;
      text-align: center;
      height: 40px;
    }
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:hover,
    &:focus {
      > div {
        color: ${(p) => p.theme.colors.midnight};
      }
    }
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 13px;
    columns: 2;
  }
  .MuiButton-text {
    padding-top: 0;
    span {
      font-weight: 400;
    }
  }
`

const StyledCategory = styled.div`
  color: ${(p) => p.theme.colors.midnight};
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-left: 26px;
    font-size: 16px;
  }
`

const StyledLink = styled(NextLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledBottomText = styled(Typography)`
  font-size: 11px;
  color: ${(p) => p.theme.colors.footerText};
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 700px;
    margin-top: 10px;
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    text-align: center;
    margin-bottom: 15px;
  }
`

const StyledCopyright = styled(Typography)`
  font-size: 11px;
  color: ${(p) => p.theme.colors.footerText};
  ${(p) => p.theme.breakpoints.down("sm")} {
    justify-self: center;
    text-align: center;
    margin-top: 10px;
  }
`
type FooterProps = {
  isPurchasedPage: boolean
  menu?: MenusResponse
}

const Footer = ({ isPurchasedPage, menu }: FooterProps) => {
  const { footerMenuDataItems } = useTypedSelector((state) => state.layout)

  const footerMenu = footerMenuDataItems || menu

  return (
    <StyledAppBar id="footer" $isPurchased={isPurchasedPage}>
      <StyledToolbar disableGutters>
        <StyledContainer>
          <StyledWrapper>
            <StyledCategories
              aria-label={`רשימה של ${footerMenu?.menuItems.length} תפריטים`}
            >
              {footerMenu?.menuItems?.map((item) => (
                <li key={item?.id}>
                  <StyledLink
                    href={item?.file?.imageUrl || item?.externalUrl}
                    openInNewTab={item.isNewTab}
                  >
                    <StyledCategory
                      aria-label={
                        item?.externalUrl
                          ? `קישור ל${item.title}`
                          : `קשור הורדת קובץ PDF נפתח החלון חדש`
                      }
                    >
                      {item.title}
                    </StyledCategory>
                  </StyledLink>
                </li>
              ))}
            </StyledCategories>
            <StyledBottomText>
              כלל התמונות והסרטונים המוצגים באתר, לרבות אלו המוצגים במסכי הרכבת
              דגם בהתאמה אישית הינם לצרכי התרשמות ראשונית ולהמחשה בלבד. מפרט
              הרכב והאבזור הקובע הינו המפרט שיצורף להסכם ההזמנה שיחתם ע”י הלקוח.
              ייתכן ולא כל הדגמים ורמות האבזור המוצעים למכירה מעודכנים ומוצגים
              באתר החברה.
            </StyledBottomText>
          </StyledWrapper>
          <StyledCopyright>כל הזכויות שמורות Geely ©</StyledCopyright>
        </StyledContainer>
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default Footer
